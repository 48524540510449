export default function searchByColumns(data, query, columns) {
    return data?.filter(item =>
        [
            'createdByName',
            'drTo',
            'mda',
            'pvNo'
          ].some(column => {
        const value = item?.voucher[column];
        return value && value.toString().toLowerCase().includes(query.toLowerCase());
      })
    );
  }