import UploadButton from './UploadButton'
import { AiOutlinePaperClip } from 'react-icons/ai';
import { MdDelete } from 'react-icons/md';
import SearchDocument from '../layouts/SearchDocument';
import { useState } from 'react';


const AddDocuments = ({
    mFilesArray,
    setMFilesArray,
    setPaymentVoucher,
    paymentVoucher
}) => {

    const [docName, setDocName] = useState("")

    const recImagg = (value) => {

        paymentVoucher?.approvalMemoUrl &&
            setPaymentVoucher({
                ...paymentVoucher,
                approvalMemoUrl: [...paymentVoucher.approvalMemoUrl, value.docPath]
            })
       
        setMFilesArray([...mFilesArray, value]);
        setDocName(value?.docTitle);

    };

    const handleDocAdd = (value) => {
        setDocName(value?.docTitle);

        paymentVoucher?.approvalMemoUrl &&
            setPaymentVoucher({
                ...paymentVoucher,
                approvalMemoUrl: [...paymentVoucher.approvalMemoUrl, value.docPath]
            })
    };
    const del = () => {
        setMFilesArray([]);
        setPaymentVoucher({
            ...paymentVoucher,
            approvalMemoUrl: []
        })
    };

    return (
        <div className="flex flex-direction-column flex-h-center" >
            <div style={{ margin: '10px', minWidth: '150px' }} >
                <UploadButton width='50%' sendImagg={recImagg} />
                <i> Supported Formats: jpeg, png, gif, pdf, ppt, pptx, vnd.ms-excel, xls, xlsx, vnd.openxmlformats-officedocument.wordprocessingml.document, doc, vnd.openxmlformats-officedocument.spreadsheetml.sheet</i>
            </div>
            <div style={{ width: '50%', margin: '10px' }}>
                <SearchDocument handleDocAdd={handleDocAdd} />
                {/* <SearchDocument handleImage={recImagg}/> */}
            </div>
            {
                (paymentVoucher?.approvalMemoUrl?.length > 0) &&
                paymentVoucher?.approvalMemoUrl?.map((url) => (
                    <div
                        style={{
                            padding: '2px',
                            marginBottom: '1px',
                            marginTop: '2px',
                            width: '80%',
                        }}
                        // style={{display:(mFilesVal.name=="" ||mFilesVal.name==null||mFilesVal.name==undefined ? "none":"flex"), marginTop:"5px", border:"2px solid red"}}
                        className='flex-between mFiles-hover flex-align'>
                        <div className='flex-align'>
                            <AiOutlinePaperClip />
                            <a target="_blank" href={url} style={{ marginLeft: 'px', marginBottom: '0px' }}>
                                View Document
                            </a>
                            <div
                                className='pointer'
                                style={{ display: 'inline', marginLeft: '6px', marginBottom: '0px' }}
                                onClick={() => {
                                    del();
                                }}>
                                <MdDelete style={{ fontSize: '16px' }} />
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}
export default AddDocuments;