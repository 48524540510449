import { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import notification from '../../utility/notification';
import Select from 'react-select';
import { get } from '../../utility/fetch';
import Ledger from './Ledger';

const types = [
    {
        value: "Capital Voucher",
        label: "Capital Ledger"
    },
    {
        value: "Recurrent Voucher",
        label: "Recurrent Ledger"
    },
]

const months = [
    {
        label: 'January',
        value: 'January'
    },
    {
        label: 'February',
        value: 'February'
    },
    {
        label: 'March',
        value: 'March'
    },
    {
        label: 'April',
        value: 'April'
    },
    {
        label: 'May',
        value: 'May'
    },
    {
        label: 'June',
        value: 'June'
    },
    {
        label: 'July',
        value: 'July'
    },
    {
        label: 'August',
        value: 'August'
    },
    {
        label: 'September',
        value: 'September'
    },
    {
        label: 'October',
        value: 'October'
    },
    {
        label: 'November',
        value: 'November'
    },
    {
        label: 'December',
        value: 'December'
    }
]

const LedgerModal = ({ type, paymentVoucher, schedule, closeModal, office }) => {

    const [banks, setBanks] = useState([]);
    const [voucherType, setVoucherType] = useState({})
    const [monthName, setMonthName] = useState({})
    const [yearName, setYearName] = useState({})
    const [showLedgerModal, setShowLedgerModal] = useState(false)
    const [recipientMda, setrecipientMda] = useState([]);
    const [mdaList, setMdaList] = useState([])




    // const validateFields = () => {
    //     let error = "";

    //     if (!bankName.label || bankName.label == "") {
    //         error = "Please Select a bank"
    //         return error;
    //     }
    //     if (!monthName.value || monthName.value == "") {
    //         error = "Please Select a month"
    //         return error;
    //     }
    //     if (!yearName.value || yearName.value == "") {
    //         error = "Please Select a year"
    //         return error;
    //     }

    //     return error;
    // }



    const getMdas = async () => {
        const res = await get(`/mdas`)
        if (res.status === 'success') {
            // this.setState({
            const roleList = res?.data.map((item) => ({
                name: `${item.name}`,
                value: item.id,
            }));
            setMdaList(roleList);

        };
    };

    const viewPreview = () => {
        let error = ""
        if (error !== "") {

            notification({
                title: 'Validation Error',
                type: 'error',
                message: error
            });
        }
        else {
            setShowLedgerModal(true);
        }
    }



    useState(() => {
        getMdas();
    }, [])

    return (
        <div className="overlay m-t-20 ">
            <IoMdClose className="close-btn pointer" onClick={() => closeModal()} />
            <div style={{ width: '80%', margin: '0 auto' }} >
                <div className=" no-padding">
                    <div
                        className="m-t-10 p-t-10 flex-h-center modal-content-large"
                        style={{
                            boxShadow: '0px 2px 6px #0000000A',
                            // backgroundColor: 'white'
                        }}
                    >
                        <p className='m-t-20'>Select Voucher Type:</p>
                        <Select
                            label="Select Voucher Type"
                            color='rgba(120,120,120,0.8)'
                            className='m-b-10 w-100'
                            onChange={(value) => {
                                setVoucherType(value);
                            }}
                            value={voucherType}
                            options={types}
                        />

                        <p className='m-t-20'>Select MDA:</p>
                        <Select
                            label="Select MDA"
                            color='rgba(120,120,120,0.8)'
                            className='m-b-10 w-w00'
                            onChange={(value) => {
                                setrecipientMda(value);
                            }}
                            value={recipientMda}
                            options={mdaList.map((m) => ({
                                label: m.name,
                                value: m.value,
                            }))}
                        />

                        <p className='m-t-20'>Select CashBook Month:</p>
                        <Select
                            label="Select Payment Bank"
                            color='rgba(120,120,120,0.8)'
                            className='m-b-10 w-100'
                            onChange={(value) => {
                                setMonthName(value);
                            }}
                            value={monthName}
                            options={months}
                        />

                        {/* <p className='m-t-20'>Select CashBook Year:</p>
                        <Select
                            label="Select Payment Bank"
                            color='rgba(120,120,120,0.8)'
                            className='m-b-10 w-100'
                            onChange={(value) => {
                                setYearName(value);
                            }}
                            value={yearName}
                            options={years}
                        /> */}

                        <button className='btn btn-large w-100' onClick={viewPreview}>Generate</button>
                    </div>
                </div>
            </div>
            {
                showLedgerModal &&
                <Ledger
                    // office={office}
                    paymentVoucher={paymentVoucher}
                    voucherType={voucherType?.value}
                    month={monthName?.value}
                    mda={recipientMda?.label}
                    closeModal={() => setShowLedgerModal(false)}
                />
            }
        </div >
    )
}

export default LedgerModal;