import { React, useState, useEffect } from "react";
import TextareaInput from "../inputs/TextareaInput";
import { IoMdClose } from "react-icons/io";
import LoaderSrc from "../../assets/svg/loading.svg";
import TextInput from "../inputs/TextInput";
import { stripCommas } from "../../utility/general";
import { numberWithCommas } from "../../utility/general";


const UpdateBalanceModal = ({data, closeModal, submit}) => {

    const [balance, setBalance] = useState("")

    const handleChange = (e) =>{
        setBalance(e.target.value);
    }

    return (
        <div
            className="overlay"
            style={{
                alignItems: "center",
                justifyContent: "center",
                padding: "40px",
                boxShadow: "0px 2px 6px #0000000A",
            }}
        >
            <div>
                <IoMdClose className="close-btn pointer" onClick={closeModal} />
                <div className="modal-box max-w-700 pd-10 ">
                    <TextInput
                        label={"Enter Account Balance"}
                        name="currentBalance"
                        value={numberWithCommas(balance)}
                        onChange={handleChange}
                        className="m-b-10"
                    />
                    <button
                        className="btn btn-large p-r w-100 flex flex-v-center flex-h-center justify-content-center m-t-10"
                        onClick={() => {
                            // setDisableBtn(true);
                            // props.action(bankBalance.id);
                            submit(balance, data);
                        }}
                        // disabled={disableBtn}
                        style={{ height: "43px" }}
                    >
                        <p style={{ margin: "auto 15px auto" }}>Submit</p>
                        {/* {disableBtn && <img src={LoaderSrc} alt="Loading..." />} */}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default UpdateBalanceModal;