import React, { useEffect, useMemo, useState, useContext } from 'react';
import { Button, Badge } from 'antd';
import notification from '../../utility/notification';
import { get, patch, put } from '../../utility/fetch';
import useMdaData from '../../utility/useMda';
import useFilteredData from '../../hooks/useFilteredData';
import SelectInput from '../inputs/SelectInput';
import SearchInput from '../inputs/SearchInput';
import { Pagination } from 'antd';
import TreasuryRequestDetails from './TreasuryRequestDetails';
import { UserInfoContext } from '../../contexts';
import { GiCancel } from 'react-icons/gi';
import { IoIosCheckmark } from 'react-icons/io';
import DocumentModal from '../layouts/DocumentModal';
import ReviewForm from '../inputs/ReviewForm';
import { Tooltip } from 'antd';
import { columnsToSearch } from '../../utility/filterColumns'
import searchByColumns from '../../utility/searchByColumns'
import { GrStatusGoodSmall } from 'react-icons/gr';
import { IoIosReturnLeft } from 'react-icons/io';
import Cookies from 'js-cookie';
import { get as getExpense } from "../../utility/fetchExpense";
import { get as getBudget } from "../../utility/fetchBudget";

import LedgerModal from "../layouts/LedgerModal"
import CashBookModal from '../layouts/CashBookModal';
import LiabilityModal from '../layouts/LiabilityModal';
import CashBudgetPreview from '../layouts/CashBudgetPreview';
import { getDeptName, getMonth, timeAgo } from '../../utility/general';
import VoteBook from '../layouts/VoteBook';


const SEQUENCE_NUMBER = 1
const FilterBadge = ({ isActive, badgeCount, text, onClick }) => {
  return (
    <div className={`m-l-10 pointer  ${isActive ? 'filter_badge_active' : 'filter_badge_inactive'}`}>
      <Badge color="red" count={badgeCount}>
        <div
          onClick={() => onClick()}
          className={`flex-h-center filter_badge_text flex-v-center`}
        >
          {text}
        </div>
      </Badge>
    </div>
  );
};

const tableHeaders = [
  'TAT',
  'PV Initiator',
  'Initiator MDA',
  'Voucher Type',
  'Date of Creation',
  'Name of Vendor/Organization',
  'Amount',
  'PV Number',
  'Reference Number',
  'Payment Voucher',
  'Schedule Form',
  'Uploaded Document',
  'Payment Cheque',
  'Status',
  'Approvals and Comments',
  'action'
];

const cashBudgetTableHeaders = [
  "Initiator",
  "MDA",
  "Initiation Date",
  "Month",
  'Year',
  'View Cash Budget Form',
  "Action"
]

const numberWithCommas = (x) => {

  if (String(x).includes(',')) {
    return String(x).replace(/,/g, '')?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  else {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  }

}


let TEMP_FILTERED_DATA = [];

const ManageDFARequest = ({ history }) => {
  const { currentUser } = useContext(UserInfoContext);
  const [title, setTitle] = useState('');
  const [showPictureModal, setShowPictureModal] = React.useState(false);
  const [activeFilter, setActiveFilter] = useState('Draft');
  let mdas = useMdaData();
  mdas = useFilteredData(mdas, 'name');
  const [modalData, setModalData] = useState({});
  const [documentType, setDocumentType] = useState("");
  const [limit, setLimit] = useState(20);
  const [reviewerComment, setReviewerComment] = useState("");
  const [showCashBookModal, setShowCashBookModal] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pendingRequests, setPendingRequests] = useState([])
  const [filteredRequest, setFilteredRequest] = useState([]);
  const [managementRequestData, setManagementRequest] = useState([]);
  const [managementRequestDataFixed, setManagementFixedRequest] = useState([]);
  const [showRequestDetailsModal, setShowRequestDetailsModal] = useState(false);
  const [cashData, setCashData] = useState([]);
  const [id, setId] = useState();
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [details, setDetails] = useState({})
  const [type, setType] = useState("");
  const [cashBudgetRequests, setCashBudgetRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredRequests, setFilteredRequests] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [showLedgerModal, setShowLedgerModal] = useState(false)
  const [showLiabilityModal, setShowLiabilityModal] = useState(false)
  const [showCashBudget, setShowCashBudget] = useState(false);
  const [capitalEconomicCodes, setCapitalEconomicCodes] = useState([])
  const [overheadEconomicCodes, setOverheadEconomicCodes] = useState([])
  const [view, setView] = useState("voucher");
  const [economicCodes, setEconomicCodes] = useState([]);
  const [showVoteBookModal, setShowVoteBookModal] = useState(false);
  const [paginationLength, setPaginationLength] = useState(0);
  

  const handleShowCashBudget = (data) =>{
    setCashData(data)
    setShowCashBudget(true);
}


  const getEconomicCodes = async () => {
    let res = await get("/voucher/economicCode")
    if (res.status === "success") {
      setEconomicCodes(res.data);

    }
  }


  const paginationChangeHandler = async (pageNumber) =>{
    getTreatedRequests(pageNumber); 
  }

  // const getExpenseBudgets = async () => {
  //     let res = await getExpense(`/api/Expenses/budgetitems/${localStorage.getItem("mdaId")}`)
  //     if (res.status === "success") {
  //         setCapitalEconomicCodes(res?.capitalExpenditure)
  //         setOverheadEconomicCodes(res?.overHeadCost)
  //     }
  // }

  const getBudgets = async () => {
    // let res = await getBudget(`/api/Expenses/budgetitems/${localStorage.getItem("mdaId")}`)
    let res = await getBudget(`/Settings`)
    if (res) {
      let obj = res?.find((item) => (
        item?.isCurrent == true
      ))

      if (obj) {
        getPeriodId(obj?.id)
      }
      // setCapitalEconomicCodes(res?.capitalExpenditure)
      // setOverheadEconomicCodes(res?.overHeadCost)
    }
  }


  const getPeriodId = async (id) => {
    // let res = await getBudget(`/api/Expenses/budgetitems/${localStorage.getItem("mdaId")}`)
    let res = await getBudget(`/v1/BudgetPlanning/GetApprovedBudgetPlanningBymdaId?periodId=${id}&mdaId=${localStorage.getItem("mdaId")}`)
    if (res) {
      setCapitalEconomicCodes(res[0]?.capitalProjects)
      setOverheadEconomicCodes(res[0]?.budgetItems)
    }
  }

  const getCashBudget = async () => {
    let res;
    if (localStorage?.getItem("accessLevel") === "1") {
      res = await get(`/CashBudget/Paginated/DFA?PageNumber=1&PageSize=100`)
    }
    else if (localStorage?.getItem("accessLevel") === "10") {
      res = await get(`/CashBudget/Paginated/PS?PageNumber=1&PageSize=100`)
    }

    if (res?.status === "success") {
      // setPaginationLength(res?.data?.totalItemCount)
      // setCashBudgetRequests(res?.data?.items);

      setCashBudgetRequests(
        res?.data?.items?.reduce((acc, item) => {
         
          acc[item.month] = acc[item.month] || [];
        acc[item.month].push(item);
        return acc;
      }, [])
      ) 
    }
  }




  const toggleRequestDetailsModal = () => {
    setShowRequestDetailsModal(!showRequestDetailsModal);
  };

  useEffect(() => {
    handlePageClick()
  }, [pendingRequests])

  const toggleReviewModal = () => {
    setShowReviewForm(!showReviewForm);
  }

  const handlePageClick = (current = 1, pageSize = 10) => {
    const startIndex = (current - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setFilteredRequest(pendingRequests?.slice(startIndex, endIndex))
    setPageSize(pageSize);
    setCurrent(current);
  };




  const approve = async (data, comment) => {
    setLoading(true);
    const { voucher, schedule } = comment;
    let payload;

    if (view === 'cash') {
      payload = {
        month: voucher?.comment?.cashData[0].month,
        year: voucher?.comment?.cashData[0].year,
        comment: voucher?.comment?.comment
      }
    }
    else {
      payload = {
        voucher: {
          id: data?.voucher?.id,
          comment: voucher.comment,
          chequeUrl: []
        },
        schedule: {
          id: data?.schedule?.id,
          comment: schedule.comment
        }
      }
    }


    try {
      let res;

      if (view === "cash") {
        res = await put(
          '/CashBudget/Approve',
          payload,
          true
        );
      }
      else {
        res = await put(
          '/Treasury/Form/Approve',
          payload,
          true
        );
      }

      if (res.status === 'success') {
        notification({
          type: 'success',
          message: "Transaction Approved Successfully"
        });
        getPendingRequests();
        getCashBudget();
        setLoading(false);
      }
      else {
        notification({
          type: 'error',
          message: res.message
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  };

  const decline = async (data, comment) => {
    const { voucher, schedule } = comment;
    setLoading(true);
    let payload 
    
    if (view === 'cash') {
      payload = {
        month: voucher?.comment?.cashData[0].month,
        year: voucher?.comment?.cashData[0].year,
        comment: voucher?.comment?.comment
      }
    }
    else {
      payload = {
        voucher: {
          id: data?.voucher?.id,
          comment: voucher.comment,
          chequeUrl: []
        },
        schedule: {
          id: data?.schedule?.id,
          comment: schedule.comment
        }
      }
    }


    try {
      const res = await put(
        '/Treasury/Form/Reject',
        payload,
        true
      );
      if (res.status === 'success') {
        notification({
          type: 'success',
          message: "Transaction Declined Successfully"
        });
        getPendingRequests();
        setLoading(false);
      }
      else {
        notification({
          type: 'error',
          message: res.message
        });
        setLoading(false);
      }
    } catch (error) {
      notification({
        type: 'error',
        message: error.message
      });
      setLoading(false);
    }
    setLoading(false);
  };

  const review = async (data, comment) => {
    const { voucher, schedule } = comment;
    setLoading(true);
    let payload;

    if (view === "cash") {
      payload = {
        month: voucher?.comment?.cashData[0].month,
        year: voucher?.comment?.cashData[0].year,
        comment: voucher?.comment?.comment
      }
    }
    else {
      payload = {
        voucher: {
          id: data?.voucher?.id,
          comment: voucher.comment,
          chequeUrl: []
        },
        schedule: {
          id: data?.schedule?.id,
          comment: schedule.comment
        }
      }
    }


    try {
      let res;
      if (view === "cash") {
        res = await put(
          '/CashBudget/Review',
          payload,
          true
        );
      }
      else {
        res = await put(
          '/Treasury/Form/Review',
          payload,
          true
        );
      }

      if (res.status === 'success') {
        if (view === "cash") {
          notification({
            type: 'success',
            message: "Cash Budget Sent Back For Review Successfully"
          });
        }
        else {
          notification({
            type: 'success',
            message: "Voucher Sent Back For Review Successfully"
          });
        }

        getPendingRequests();
        setLoading(false);
      }
      else {
        notification({
          type: 'error',
          message: res.message
        });
        setLoading(false);
      }
    } catch (error) {
      notification({
        type: 'error',
        message: error.message
      });
      setLoading(false);
    }
    setLoading(false);
  };

  const closeModal = () => {
    setShowPictureModal(false);
  };

  const showDocumentModal = (type, data) => {
    setModalData(data);
    setDocumentType(type);
    setShowPictureModal(true);
  }



  const getPendingRequests = async () => {
    try {
      let res;
      if (Cookies.get('access') === 'DFA') {
        res = await get(`/Treasury/Form/CounterSigningOfficer`);
      }
      else {
        res = await get(`/Treasury/Form`);
      }

      if (res.status === 'success') {
        setPendingRequests(res?.data.filter((item) => item.statusDetails !== 'ApprovalCompleted'))
        setTotal(res?.data?.length)
        setFilteredRequest(res.data.filter((item) => item.statusDetails !== 'ApprovalCompleted'));
        setActiveFilter('Pending');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTreatedRequests = async (approvedPageNumber = 1) => {
    try {
      const res = await get(`/Treasury/Form/MyTreated?PageNumber=${approvedPageNumber}&PageSize=${10}`);
      // const res1 = await get(`/Treasury/Form/Rejected`);
      console.log(res);
      if (res.status === 'success') {
        // setPendingRequests([...res.data, res1.data].sort((a,b)=>a.dateModified - b.dateModified))
        setPendingRequests(res?.data?.items)
        setPaginationLength(res?.data?.pageCount * 10)
        setActiveFilter('Approved');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const closeVoteBookModal = () =>{
    setShowVoteBookModal(false)
  }


  const performPageMetaActions = () => {
    window.scrollTo(0, 0);
    document.title = `EDSG - Manage ${Cookies.get('access')} Request`;
    setTitle(`Manage ${Cookies.get('access')} Request`);
  };

  useEffect(() => {
    performPageMetaActions();
    getPendingRequests();
    getCashBudget();
    getEconomicCodes();
    // getExpenseBudgets();
    getBudgets()
    localStorage.removeItem("requisitionNumber")
    localStorage.removeItem("approvedAmount")
    localStorage.removeItem("totalAmount")
    localStorage.removeItem("expenseType")
  }, []);

  const filterList = useMemo(() => {
    return [
      {
        text: 'Pending',
        badgeCount: managementRequestDataFixed.filter(
          (d) => d.treasuryRequestStatus === 2
        ).length,
        isActive: activeFilter === 'Pending',
        onClick: () => { getPendingRequests() }
      },
      {
        text: 'Treated',
        badgeCount: managementRequestDataFixed.filter(
          (d) => d.treasuryRequestStatus === 3
        ).length,
        isActive: activeFilter === 'Approved',
        onClick: () => { getTreatedRequests(1) }
      }
    ];
  }, [activeFilter, managementRequestDataFixed]);


  const filterAndPaginateData = (searchText) => {
    const filteredData = searchByColumns(pendingRequests, searchText, columnsToSearch)

    // Calculate pagination parameters
    const startIndex = (current - 1) * pageSize;
    const paginatedData = filteredData.slice(startIndex, startIndex + pageSize);

    return { paginatedData, totalItems: filteredData.length };
  }

  const handleSearch = (e) => {
    // Update table data and total items
    const { paginatedData, totalItems } = filterAndPaginateData(e.target.value);
    setFilteredRequest(paginatedData);
    setTotal(totalItems);

    // Reset to the first page when performing a new search
    setCurrent(1);

  }



  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">{title}</div>
      <div className="w-100 m-t-10 flex  flex-v-center space-between">
        {/* <SelectInput
          onChange={onInputChange}
          placeholder="Filter Items By"
          options={['All', ...mdas]}
        /> */}
        {(localStorage?.getItem("accessLevel") === "1" || localStorage?.getItem("accessLevel") === "10") && <div>
          <Badge count={filteredRequest?.length > 0 ? filteredRequest?.length : null}><button onClick={() => setView("voucher")} className="btn m-r-10">Vouchers</button></Badge>
          <Badge count={cashBudgetRequests?.length > 0 ? 1 : null}><button onClick={() => setView("cash")} className="btn m-l-20">Cash Budget</button></Badge>
        </div>}
        <SearchInput
          onChange={(e) => { handleSearch(e) }}
          className="w-30"
          placeholder="Search vouchers"
        />
      </div>
      {view !== "cash" && <div className="w-100 m-t-20 p-x-10 p-y-10 bg_green  space-between flex   flex-v-center flex-h-end">
        <div className="bold-text">Request Log</div>
        <div className=" flex   flex-v-center flex-h-end">
          {filterList.map((filter) => (
            <FilterBadge {...filter} />
          ))}
        </div>
      </div>}






      {view === "voucher" && <div className="overflow-table">
        <table className="table-view">
          <thead className="">
            <tr className="tr-bordered">
              {tableHeaders.map((th, index) => (
                <th key={index} className="th th-bordered">
                  <p>{th}</p>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-body">
            {filteredRequest?.map((item) => (
              <tr role="presentation">
                <td className="td p-r td-bordered">
                  <div className='w-100 flex flex-col flex-h-center flex-v-center'>
                    <div style={{ width: "16px", height: "16px" }} className={`m-r-10 float-left ${item?.voucher?.over24Hrs === false ? 'bg-bg-green' : (item?.voucher?.over24Hrs === true && item?.voucher?.over48Hrs === false) ? 'bg-bg-amber' : (item?.voucher?.over24Hrs === true && item?.voucher?.over48Hrs === true) ? 'bg-bg-red' : 'bg-gray'}`}></div>
                    <div className='no-wrap m-t-10'>{timeAgo(new Date(item?.voucher?.dateModified))}</div>
                  </div>
                </td>
                <td className="td p-r td-bordered">
                  {item?.voucher?.createdByName}
                </td>
                <td className="td p-r td-bordered">
                  {item?.voucher?.mda}
                </td>
                <td className="td p-r td-bordered">
                  {item?.voucher?.voucherType}
                </td>
                <td className="td p-r td-bordered">
                  {new Date(item?.voucher?.dateCreated).toUTCString()}
                </td>
                <td className="td p-r td-bordered">
                  {item?.voucher?.drTo}
                </td>
                <td className="td p-r td-bordered">
                  {`₦${numberWithCommas(Number(parseFloat(item?.voucher?.amount) + item?.voucher?.subVouchers?.reduce(
                    (accumulator, currentValue) => accumulator + currentValue?.amount, 0,
                  )).toFixed(2))}`}
                </td>
                <td className="td p-r td-bordered">
                  {item?.voucher?.pvNo}
                </td>
                <td className="td p-r td-bordered">
                  {item?.voucher?.referenceNumber}
                </td>
                <td className="td p-r td-bordered">
                  <a onClick={() => { showDocumentModal("voucher", item) }}>View Payment Voucher</a>
                </td>
                <td className="td p-r td-bordered">
                  <a onClick={() => { showDocumentModal("schedule", item) }}>View Schedule Form</a>
                </td>
                <td className="td p-r td-bordered">
                  <a onClick={() => { showDocumentModal("memo", item) }}>View Approved Memo</a>
                </td>
                <td className="td p-r td-bordered">
                  <a onClick={() => { showDocumentModal("paymentCheque", item) }}>View Payment Cheque</a>
                </td>
                <td className="td p-r td-bordered" width={"10%"}>
                  <div >
                    <GrStatusGoodSmall size={15} className={`m-r-10 float-left ${item?.voucher?.statusDetails === 'Pending' ? 'bg-amber' : item?.voucher?.statusDetails === 'ApprovalCompleted' ? 'bg-green' : item?.voucher?.statusDetails === 'Rejected' ? 'bg-red' : 'bg-gray'}`} />
                    <div className='float-right'>{item?.voucher?.statusDetails === 'Pending' ? ` With ${getDeptName(item?.voucher?.journeyStateId)}` : item?.voucher?.statusDetails === 'ApprovalCompleted' ? 'Paid' : item?.voucher?.statusDetails === 'Rejected' ? 'Declined' : item?.voucher?.statusDetails}</div>
                  </div>
                </td>
                <td className="td p-r td-bordered">
                  <a onClick={() => { showDocumentModal("comment", item) }}>View Approvals and Comments</a>
                </td>
                {activeFilter !== 'Approved' ? <td className="td p-r td-bordered" width={"10%"}>
                  {
                    <div className="flex space-around">
                      {/* <Tooltip title="Decline"><GiCancel size={30} color="red" className="m-r-10 pointer" onClick={() => { setDetails(item); setShowReviewForm(true); setType("decline") }} /></Tooltip> */}
                      <Tooltip title="Approve"><IoIosCheckmark size={35} color="green" className="pointer" onClick={() => { setDetails(item); setShowReviewForm(true); setType("approve") }} /></Tooltip>
                      <Tooltip title="Send Back for Review"><IoIosReturnLeft size={30} color="grey" className="pointer" onClick={() => { setDetails(item); setShowReviewForm(true); setType("review") }} /></Tooltip>

                    </div>
                  }
                </td>
                  :
                  <td className="td p-r td-bordered">
                    <p >Treated</p>
                  </td>
                }
              </tr>
            ))}
          </tbody>
        </table>
        {Cookies.get('access') === "ExpenseControl" && activeFilter === 'Approved' && <button onClick={() => setShowLedgerModal(!showLedgerModal)} className="btn btn-large w-100 m-t-20" >Generate Ledger</button>}
        {Cookies.get('access') === "ExpenseControl" && activeFilter === 'Approved' && <button onClick={() => setShowLiabilityModal(!showLiabilityModal)} className="btn btn-large w-100 m-t-20" >Generate Liability</button>}
        {((localStorage.getItem('accessLevel') === "1" && activeFilter === 'Approved') || (localStorage.getItem('accessLevel') === "10" && activeFilter === 'Approved')) && <button className="btn btn-large w-100 m-t-20" onClick={() => setShowCashBookModal(!showCashBookModal)}>Generate Cashbook</button>}
        {((localStorage.getItem('accessLevel') === "1" && activeFilter === 'Approved') || (localStorage.getItem('accessLevel') === "10" && activeFilter === 'Approved')) && <button className="btn btn-large w-100 m-t-20" onClick={() => setShowVoteBookModal(true)}>Show Overhead VoteBook</button>}

        {showVoteBookModal && <VoteBook economicCodes = {economicCodes} capitalEconomicCodes = {capitalEconomicCodes} overheadEconomicCodes = {overheadEconomicCodes} closeModal={closeVoteBookModal}/> }

      </div>
      }

      {view === "voucher" && <div style={{ marginTop: '20px' }}>
        <Pagination
          // showSizeChanger
          pageSize={pageSize}
          // onShowSizeChange={paginationChangeHandler}
          current={current}
          total={paginationLength}
          // showTotal={true}
          onChange={paginationChangeHandler}
          // pageSizeOptions={pageSizeOptions}
          responsive
        />
      </div>}

      

      {/* {showRequestDetailsModal && (
        <TreasuryRequestDetails
          sequenceNumber={SEQUENCE_NUMBER}
          updateReviewerComment={value => setReviewerComment(value)}
          closeModal={toggleRequestDetailsModal}
          id={id}
        />
      )} */}



      {view === "cash" &&
        <div>
          <div className="overflow-table m-t-80">
            <table className="table-view" >
              <thead className="">
                <tr className="tr-bordered">
                  {cashBudgetTableHeaders.map((th, index) => (
                    <th key={index} className="th th-bordered">
                      <p>{th}</p>
                    </th>
                  ))}
                </tr>
              </thead>
              {cashBudgetRequests?.length > 0 && <tbody className="table-body">
                {
                  cashBudgetRequests.map( item => (
                  <tr role="presentation">
                    <td className="td p-r td-bordered">
                      {item[0]?.createdByName}
                    </td>
                    <td className="td p-r td-bordered">
                      {item[0]?.mda}
                    </td>
                    <td className="td p-r td-bordered">
                      {new Date(item[0]?.dateCreated).toUTCString()}
                    </td>
                    <td className="td p-r td-bordered">
                      {getMonth(item[0]?.month)}
                    </td>
                    <td className="td p-r td-bordered">
                      {item[0]?.year}
                    </td>
                    <td className="td p-r td-bordered">
                      <button onClick={() => handleShowCashBudget(item)} className="btn w-100">View Form</button>
                    </td>
                    <td className="td p-r td-bordered" width={"17%"}>
                      <div className='flex space-between'>
                        <div className={`status_ball m-r-10 ${item[0]?.statusDetails === 'Pending' ? 'bg-amber' : item[0]?.statusDetails === 'ApprovalCompleted' ? 'bg-green' : item[0]?.statusDetails === 'Rejected' ? 'bg-red' : 'bg-gray'}`}>&nbsp;</div>
                        <div>
                          {
                            <div>
                              <Tooltip title="Approve"><IoIosCheckmark size={35} color="green" className="pointer" onClick={() => { setShowReviewForm(true); setType("approve"); setCashData(item) }} /></Tooltip>
                              <Tooltip title="Send Back for Review"><IoIosReturnLeft size={30} color="grey" className="pointer" onClick={() => { setShowReviewForm(true); setType("review"); setCashData(item) }} /></Tooltip>
                            </div>
                          }
                        </div>
                      </div>
                    </td>
                  </tr>))
                }
              </tbody>}
            </table>
          </div>

          {/* <div style={{ marginTop: '20px' }}>
                <Pagination
                    showSizeChanger
                    // pageSize={pageSize}
                    // onShowSizeChange={paginationChangeHandler}
                    // current={pageNumber}
                    total={paginationLength}
                    // showTotal={true}
                    onChange={paginationChangeHandler}
                    // pageSizeOptions={pageSizeOptions}
                    responsive
                />
            </div> */}
          {showCashBudget && <CashBudgetPreview data={cashData} closeModal={() => setShowCashBudget(false)} review={true} />}
        </div>
      }






      {
        showLedgerModal && <LedgerModal paymentVoucher={pendingRequests} closeModal={() => setShowLedgerModal(!showLedgerModal)} office="TCA" />
      }

      {
        showLiabilityModal && <LiabilityModal paymentVoucher={pendingRequests} closeModal={() => setShowLiabilityModal(!showLiabilityModal)} office="TCA" />
      }

      {
        showPictureModal && <DocumentModal closeModal={closeModal} paymentVoucher={modalData.voucher} schedule={modalData.schedule} type={documentType} />
      }

      {
        showCashBookModal && <CashBookModal isDFA={true} paymentVoucher={pendingRequests} closeModal={() => setShowCashBookModal(!showCashBookModal)} office={localStorage?.getItem('accessLevel') === "10" ? "PS" : "DFA"} />
      }

      {
        showReviewForm && <ReviewForm closeModal={toggleReviewModal} cashData={cashData} approve={approve} review={review} decline={decline} details={details} type={type} loading={loading} view={view} />
      }









    </div>
  );
};

export default ManageDFARequest;
