import * as React from "react";
import TextareaInput from "../inputs/TextareaInput";
import { IoMdClose } from "react-icons/io";
import LoaderSrc from "../../assets/svg/loading.svg";
import SelectApprovers from "./SelectApprovers";
import TextInput from "../inputs/TextInput";
import UploadButton from "./UploadButton";
import { get, post } from "../../utility/fetch";
import notification from "../../utility/notification";
import { useEffect } from "react";
import Cookies from "js-cookie";

const ConfirmReceipt = ({ closeModal, paymentVoucher, ...props }) => {
    const [disableBtn, setDisableBtn] = React.useState(false);
    const [docName, setDocName] = React.useState("");
    const [payload, setPayload] = React.useState({
        voucherId: 0,
        userId: 0,
        vendor: "",
        receipt: "",
        comment: ""
    });
    const [firstForm, setFirstForm] = React.useState(null);
    const [voucherReceipt, setVoucherReceipt] = React.useState({});

    useEffect(() => {
        getReceipt();
    }, [])


    const recImagg = (value) => {
        setPayload({
            ...payload,
            receipt: value.docPath
        })
        setDocName(value.docTitle)
    };

    const receiveImagg = (value) => {
        setPayload({
            ...payload,
            receipt: value.docPath
        })
        setDocName(value.docTitle)
        setFirstForm(value.docTitle)
    };


    const handleReceiverId = async (e) => {

        setPayload({
            ...payload,
            userId: Number(e?.value),
        })

    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPayload((prev) => ({ ...prev, [name]: value }));
    }

    const getReceipt = async () => {
        const res = await get("/Treasury/Receiver/SignOff")
        if (res && res.status === 'success') {
            setVoucherReceipt(res.data?.filter((data) => data.voucherId === paymentVoucher.id))
        }
    }


    const submitReceipt = async () => {
        const res = await post("/Treasury/Receiver/SignOff", { ...payload, voucherId: paymentVoucher.id })
        if (res && res.status === 'success') {
            if (res.code === 1) {
                setFirstForm(null)
                notification({
                    title: `Successful Receipt Submission`,
                    message: res.message,
                    type: 'success'
                });
                getReceipt();
            } else {
                notification({
                    title: `Receipt Submission Error`,
                    message: res.message,
                    type: 'error'
                });
            }
        } else {
            notification({
                title: 'Network Error',
                message: `Something went wrong while creating an Request. Please, try again later.`,
                type: 'error'
            });
        }
    }
    return (

        <div>
            {/* <IoMdClose className="close-btn pointer" onClick={closeModal} /> */}
            {Object.keys(voucherReceipt).length !== 0 && voucherReceipt[0].userId !== 0 ?

                <div className="modal-box max-w-700 pd-10 p-40">

                    <div className="flex align-base">
                        <h2>Staff Name: </h2> <h3 className="m-l-10">{voucherReceipt[0].beneficiary}</h3>
                    </div>
                    <div className="flex align-base">
                        <h2>Comment: </h2> <h3 className="m-l-10">{voucherReceipt[0]?.comment}</h3>
                    </div>
                    <p></p>
                </div>
                : (Object.keys(voucherReceipt).length !== 0 && voucherReceipt[0].receipt !== "") ?

                    <div className="modal-box max-w-700 pd-10 p-40">
                        <div className="flex align-base">
                            <h2>Vendor Name: </h2> <h3 className="m-l-10">{voucherReceipt[0].vendor}</h3>
                        </div>
                        <a href={voucherReceipt[0]?.receipt} target="_blank" rel="noopener noreferrer">View Uploaded Document</a>
                        <div className="flex align-base">
                            <h2>Comment: </h2> <h3 className="m-l-10">{voucherReceipt[0]?.comment}</h3>
                        </div>

                    </div>
                    :
                    (Cookies.get("access") !== "AG" && Cookies.get("access") === "ExpenseControl") && <div className="modal-box max-w-700 pd-10 p-40">
                        <h2>MDA ONLY:</h2>
                        <SelectApprovers
                            mdaLabel="Receiver's MDA"
                            employeeLabel="Receiver"
                            handleApproverId={handleReceiverId}
                        />
                        <UploadButton
                            sendImagg={receiveImagg}
                        />
                        <i> Supported Formats: jpeg, png, gif, pdf, ppt, pptx, vnd.ms-excel, xls, xlsx, vnd.openxmlformats-officedocument.wordprocessingml.document, doc, vnd.openxmlformats-officedocument.spreadsheetml.sheet</i>

                        <div>{payload.receipt && firstForm && <a target="_blank" href={payload.receipt}>{docName}</a>}</div>

                        <TextareaInput
                            label={"Add Comment"}
                            name="Comment"
                            value={props.state && props.state.comment}
                            rows={5}
                            onChange={handleChange}
                            className="m-t-15"
                        />
                        <h2 className="m-t-40 m-b-20">VENDOR ONLY:</h2>
                        <TextInput name="vendor" label="Name Of Vendor" onChange={handleChange} />
                        <UploadButton
                            sendImagg={recImagg}
                        />
                        <i> Supported Formats: jpeg, png, gif, pdf, ppt, pptx, vnd.ms-excel, xls, xlsx, vnd.openxmlformats-officedocument.wordprocessingml.document, doc, vnd.openxmlformats-officedocument.spreadsheetml.sheet</i>
                        <div>{payload.receipt && !firstForm && <a target="_blank" href={payload.receipt}>{docName}</a>}</div>


                        <TextareaInput
                            label={"Add Comment"}
                            name="Comment"
                            value={props.state && props.state.comment}
                            rows={5}
                            onChange={handleChange}
                            className="m-t-15"
                        />

                        <button
                            className="btn btn-large p-r w-100 flex flex-v-center flex-h-center justify-content-center m-t-10"
                            onClick={() => {
                                submitReceipt();
                            }}
                            disabled={disableBtn}
                            style={{ height: "43px" }}
                        >
                            <p style={{ margin: "auto 15px auto" }}>Submit</p>
                            {disableBtn && <img src={LoaderSrc} alt="Loading..." />}
                        </button>
                    </div>}
        </div>

    );
};

export default ConfirmReceipt;
