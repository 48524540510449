/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { BiEnvelope } from 'react-icons/bi';
import { BsCardList, BsListCheck } from 'react-icons/bs';
import { FaMoneyBillWave } from 'react-icons/fa';
import { GoRepoClone } from 'react-icons/go';
import { IoMdSettings } from 'react-icons/io';
import { AiOutlineSelect } from "react-icons/ai";
import { RiSettingsFill } from "react-icons/ri";

const sidebarMenu = () => {
  // console.log(localStorage?.getItem('access'))

  let isHead = localStorage?.getItem("isHead");

  let menuList;
  if (localStorage?.getItem('accessLevel') === "1") {
    return menuList = [
      {
        title: 'Dashboard',
        href: '/treasuryops',
        icon: <BiEnvelope className="icon" />
      },
      {
        title: "Raise Cash Budget",
        href: '/treasuryops/cash_budget',
        icon: <BsCardList className="icon" />
      },
      {
        title: "Saved Requests",
        href: '/treasuryops/treasury_request',
        icon: <BsCardList className="icon" />
      },
      {
        title: "Initiate Capital Voucher",
        href: '/treasuryops/initiate_capital',
        icon: <AiOutlineSelect className="icon" />
      },
      {
        title: "Initiate Overhead Voucher",
        href: '/treasuryops/initiate_overhead',
        icon: <AiOutlineSelect className="icon" />
      },
      {
        title: "Initiate Salary Voucher",
        href: '/treasuryops/initiate_salary',
        icon: <AiOutlineSelect className="icon" />
      },
      {
        title: "Manage Request",
        href: '/treasuryops/manage_DFA_request',
        icon: <BsCardList className="icon" />
      },
    ];

  }
  else if (localStorage?.getItem('accessLevel') === "9") {
    return menuList = [
      {
        title: 'Dashboard',
        href: '/treasuryops',
        icon: <BiEnvelope className="icon" />
      },
      {
        title: "Raise Cash Budget",
        href: '/treasuryops/cash_budget',
        icon: <BsCardList className="icon" />
      },
      {
        title: "Saved Requests",
        href: '/treasuryops/treasury_request',
        icon: <BsCardList className="icon" />
      },
      {
        title: "Initiate Capital Voucher",
        href: '/treasuryops/initiate_capital',
        icon: <BsCardList className="icon" />
      },
      {
        title: "Initiate Overhead Voucher",
        href: '/treasuryops/initiate_overhead',
        icon: <BsCardList className="icon" />
      },
      {
        title: "Initiate Salary Voucher",
        href: '/treasuryops/initiate_salary',
        icon: <BsCardList className="icon" />
      },


    ];

  }
  else if (localStorage?.getItem('accessLevel') === "2") {
    if (localStorage?.getItem('isHead') === "true") {
      return menuList = [
        {
          title: 'Dashboard',
          href: '/treasuryops',
          icon: <BiEnvelope className="icon" />
        },
        {
          title: 'Internal Auditor',
          href: '/treasuryops/manage_audit_request',
          icon: <GoRepoClone className="icon" />,
          children: [
            {
              title: 'Treasury Ops',
              href: '/treasuryops/manage_audit_request',
              icon: <BsCardList className="icon" />
            },
          ]
        },
        {
          title: "Settings",
          href: '/treasuryops/settings',
          icon: <IoMdSettings className="icon" />
        },

      ]
    }
    else {
      return menuList = [
        {
          title: 'Dashboard',
          href: '/treasuryops',
          icon: <BiEnvelope className="icon" />
        },
        {
          title: 'Internal Auditor',
          href: '/treasuryops/manage_audit_request',
          icon: <GoRepoClone className="icon" />,
          children: [
            {
              title: 'Treasury Ops',
              href: '/treasuryops/manage_audit_request',
              icon: <BsCardList className="icon" />
            },
          ]
        },
      ]
    }

  }
  else if (localStorage?.getItem('accessLevel') === "10") {
    if (localStorage?.getItem('isHead') === "true") {
      return menuList = [
        {
          title: 'Dashboard',
          href: '/treasuryops',
          icon: <BiEnvelope className="icon" />
        },
        {
          title: 'PS',
          href: '/treasuryops/accountant_general',
          icon: <GoRepoClone className="icon" />,
          children: [

            {
              title: 'Treasury Ops',
              href: '/treasuryops/manage_ps_request',
              icon: <BsCardList className="icon" />
            },
          ]
        },
        {
          title: "Settings",
          href: '/treasuryops/settings',
          icon: <IoMdSettings className="icon" />
        },
      ]

    } else {
      return menuList = [
        {
          title: 'Dashboard',
          href: '/treasuryops',
          icon: <BiEnvelope className="icon" />
        },
        {
          title: 'PS',
          href: '/treasuryops/accountant_general',
          icon: <GoRepoClone className="icon" />,
          children: [

            {
              title: 'Treasury Ops',
              href: '/treasuryops/manage_ps_request',
              icon: <BsCardList className="icon" />
            },
          ]
        },
      ]
    }

  }
  else if (localStorage?.getItem('accessLevel') === "11") {
    if (localStorage?.getItem("isHead") === "true") {
      return menuList = [
        {
          title: 'Dashboard',
          href: '/treasuryops',
          icon: <BiEnvelope className="icon" />
        },
        {
          title: 'Inspectorate',
          href: '/treasuryops/accountant_general',
          icon: <GoRepoClone className="icon" />,
          children: [
            {
              title: 'Treasury Ops',
              href: '/treasuryops/manage_inspectorate_request',
              icon: <BsCardList className="icon" />
            },
          ]
        },
        {
          title: "Settings",
          href: '/treasuryops/settings',
          icon: <IoMdSettings className="icon" />
        },
      ]

    } else {
      return menuList = [
        {
          title: 'Dashboard',
          href: '/treasuryops',
          icon: <BiEnvelope className="icon" />
        },
        {
          title: 'Inspectorate',
          href: '/treasuryops/accountant_general',
          icon: <GoRepoClone className="icon" />,
          children: [
            {
              title: 'Treasury Ops',
              href: '/treasuryops/manage_inspectorate_request',
              icon: <BsCardList className="icon" />
            },
          ]
        },
      ]
    }

  }
  else if (localStorage?.getItem('accessLevel') === '3') {
    if (localStorage?.getItem("isHead") === "true") {
      return menuList = [
        {
          title: 'Dashboard',
          href: '/treasuryops',
          icon: <BiEnvelope className="icon" />
        },
        {
          title: 'Final Account',
          href: '/treasuryops/accountant_general',
          icon: <GoRepoClone className="icon" />,
          children: [
            {
              title: 'Treasury Ops',
              href: '/treasuryops/manage_final_request',
              icon: <BsCardList className="icon" />
            },
          ]
        },
        {
          title: "Settings",
          href: '/treasuryops/settings',
          icon: <IoMdSettings className="icon" />
        },

      ]
    } else {
      return menuList = [
        {
          title: 'Dashboard',
          href: '/treasuryops',
          icon: <BiEnvelope className="icon" />
        },
        {
          title: 'Final Account',
          href: '/treasuryops/accountant_general',
          icon: <GoRepoClone className="icon" />,
          children: [
            {
              title: 'Treasury Ops',
              href: '/treasuryops/manage_final_request',
              icon: <BsCardList className="icon" />
            },
          ]
        },
      ]
    }

  }
  else if (localStorage?.getItem('accessLevel') === '4') {
    if (localStorage?.getItem("isHead") === "true") {
      return menuList = [
        {
          title: 'Dashboard',
          href: '/treasuryops',
          icon: <BiEnvelope className="icon" />
        },
        {
          title: 'Expenditure Control',
          href: '/treasuryops/accountant_general',
          icon: <GoRepoClone className="icon" />,
          children: [
            {
              title: 'Treasury Ops',
              href: '/treasuryops/manage_expedition_request',
              icon: <BsCardList className="icon" />
            },
          ]
        },
        {
          title: "Settings",
          href: '/treasuryops/settings',
          icon: <IoMdSettings className="icon" />
        },
      ]
    }
    else {
      return menuList = [
        {
          title: 'Dashboard',
          href: '/treasuryops',
          icon: <BiEnvelope className="icon" />
        },
        {
          title: 'Expenditure Control',
          href: '/treasuryops/accountant_general',
          icon: <GoRepoClone className="icon" />,
          children: [
            {
              title: 'Treasury Ops',
              href: '/treasuryops/manage_expedition_request',
              icon: <BsCardList className="icon" />
            },
          ]
        },

      ]
    }
  }
  else if (localStorage?.getItem('accessLevel') === '5') {
    return menuList = [

      {
        title: 'Accountant General',
        href: '/treasuryops/accountant_general',
        icon: <GoRepoClone className="icon" />,
        children: [
          {
            title: 'Treasury Ops',
            href: '/treasuryops/manage_AG_request',
            icon: <BsCardList className="icon" />
          },
        ]
      },
      {
        title: 'Finance Dashboard',
        href: '/treasuryops/finance-dashboard',
        icon: <FaMoneyBillWave className="icon" />
      },
      {
        title: 'Finance Report',
        href: '/treasuryops',
        icon: <BiEnvelope className="icon" />
      },
      {
        title: 'Account Balance Report',
        href: '/treasuryops/balance',
        icon: <BiEnvelope className="icon" />
      },
      {
        title: 'Bank Account Settings',
        href: '/treasuryops/balance-settings',
        icon: <RiSettingsFill className="icon" />
      },
      // {
      //   title: 'Finance Report-(Archived)',
      //   href: '/treasuryops/archived',
      //   icon: <BiEnvelope className="icon" />
      // },
    ]
  }
  else if (localStorage?.getItem('accessLevel') === '6') {
    return menuList = [
      {
        title: 'Dashboard',
        href: '/treasuryops',
        icon: <BiEnvelope className="icon" />
      },
      {
        title: 'Management Account',
        href: '/treasuryops/management_account',
        icon: <GoRepoClone className="icon" />,
        children: [
          {
            title: 'Treasury Ops',
            href: '/treasuryops/manage_Mgt_account_request',
            icon: <BsCardList className="icon" />
          },

        ]
      },
    ]
  }
  else if (localStorage?.getItem('accessLevel') === '7') {
    return menuList = [
      {
        title: 'Dashboard',
        href: '/treasuryops',
        icon: <BiEnvelope className="icon" />
      },
      {
        title: 'Treasury Cash Account',
        href: '/treasuryops/treasury_cash_account',
        icon: <GoRepoClone className="icon" />,
        children: [
          {
            title: 'Treasury Ops',
            href: '/treasuryops/treasury_cash_account_request',
            icon: <BsCardList className="icon" />
          },

        ]
      },
    ]
  }
  else if (localStorage?.getItem('accessLevel') === '8') {
    return menuList = [
      {
        title: 'Dashboard',
        href: '/treasuryops',
        icon: <BiEnvelope className="icon" />
      },

      {
        title: "Settings",
        href: '/treasuryops/settings',
        icon: <IoMdSettings className="icon" />
      },
    ]
  }
  else if (localStorage?.getItem('accessLevel') === '16') {
    return menuList = [
      {
        title: 'Dashboard',
        href: '/treasuryops',
        icon: <BiEnvelope className="icon" />
      },
    ]
  }

}

export default sidebarMenu;