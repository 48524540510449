import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
// import ReactNotifications from 'react-notifications-component';

import { UserInfoContext } from './contexts';

import Homepage from './components/home';

import ProtectedRoute from './routes/ProtectedRoute';
import UserPortal from './components/UserPortal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const history = createBrowserHistory();
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    const userInfo = localStorage.getItem('USER_INFO');
    if (userInfo) {
      setCurrentUser(JSON.parse(userInfo));
    }
  }, []);

  return (
    <UserInfoContext.Provider value={{ currentUser, setCurrentUser }}>
      <Router history={history}>
        <div>
          {/* <ReactNotifications /> */}
          <Switch>
            <Route path="/" exact component={Homepage} />

            <ProtectedRoute path="/treasuryops/" component={UserPortal} />
            <Route render={() => <h1>Error 404. Page not found.</h1>} />
          </Switch>
          <ToastContainer />
        </div>
      </Router>
    </UserInfoContext.Provider>
  );
}

export default App;
