/* eslint-disable no-console */
import React, {useState} from 'react';
import { GiTrashCan } from 'react-icons/gi';
import { GrEdit } from 'react-icons/gr';
// import ReactTooltip from 'react-tooltip';
import PictureModal from '../layouts/PictureModal'

const bottomBorder = {
  borderBottom: '.5px solid rgba(99,99,99,.6)',
};

const DocTable = ({ action = true, ...props }) => {

  const [showPicture, setShowPicture] = useState(false)
  const [picture, setPicture] = useState('')


  const showPictureModal =(pic)=>{
    setPicture(pic);
    setShowPicture(true);
}

const closePictureModal = () =>{
  setShowPicture(false);
}


  return (
    <div className='w-100 no-margin h-100'>
      <div
        className='table-view '
        style={{ borderCollapse: 'collapse', border: 'none', ...props.overrideTableStyles }}>
        <div
          className='table-header'
          style={{
            backgroundColor: `${props.headerBGColor || '#43425D'}`,
            color: `${props.headerColor || 'white'}`,
          }}>
          <div className='table-row'>
            {props.headerIcon ? <div className='th'>{props?.headerIcon}</div> : null}

            {props?.headers?.map((item, idx) => (
              <div className='th' key={idx}>
                <p>{item}</p>
              </div>
            ))}
            {action && (
              <div className='th right-text'>
                <div className='add-button'>Action</div>
              </div>
            )}
          </div>
        </div>
        <div className='table-body no-border'>
          {props?.tableBody.length > 0 ? (
            props?.tableBody?.map((item, idx) => (
              <div className='table-row no-border' key={idx} style={{ ...bottomBorder }}>
                {props?.headerIcon && props?.editIcon && (
                  <div className='td left-text font-sz-22 pointer' style={{ ...bottomBorder }}>
                    <div className='' data-tip='Edit' style={{ fontSize: '13px' }}>
                      <GrEdit
                        color={'#fff'}
                        className='font-sz-22'
                        onClick={() => props?.setEditDetails(item)}
                      />
                      {/* <span className='tooltiptext'>Edit</span> */}
                    </div>
                    {/* <ReactTooltip /> */}
                  </div>
                )}

                <div key={idx} className='td left-text pointer' style={{ ...bottomBorder }}>
                  <div>
                    <p
                      style={{
                        textDecoration: 'underline',
                        marginTop: 'auto',
                      }}>
                      {(item.docPath?.includes('.jpg') || item.docPath?.includes('.png') || item.docPath?.includes('.gif'))
                            ?
                            <div
                              style={{ display: 'flex', alignItems: 'center', color:'blue', textDecoration:'underline' }}
                              onClick={()=>showPictureModal(item.docPath)}
                              className='d-flex m-b-10 '>
                              <a className='flex' >
                                <p className='pointer'>{item.docTitle}</p>
                              </a>
                              
                            </div>
                            :
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                              className='d-flex '>
                              <a className='flex m-b-10' target='_blank' href={`${item.docPath}`}>
                                <p className='pointer'>{item.docTitle}</p>
                              </a>
                              
                            </div>}
                    </p>
                    {/* <ReactTooltip /> */}
                  </div>
                </div>

                {action && (
                  <div className='td right-text font-sz-22 pointer' style={{ ...bottomBorder }}>
                    <div className='' data-tip='Delete'>
                      {/* <span className='tooltiptext' style={{ fontSize: '13px' }}>
                    Delete
                  </span> */}
                      <GiTrashCan
                        className=' font-sz-22'
                        onClick={() => {
                          // props?.deleteFunc(props.endpoint, item, props?.itemName);
                          props?.setShowDelete(true);
                          props?.setItemName(props?.itemName);
                          props?.setEndpoint(props?.endpoint);
                          props?.setCurrentItem(item);
                          props?.setQuestion(
                            'Are you sure you want to delete this supporting Document ?',
                          );
                        }}
                      />
                    </div>
                    {/* <ReactTooltip /> */}
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className='m-l-10 td left-text '>No Supporting Document</div>
          )}
        </div>
        {
          showPicture && <PictureModal closeModal = {closePictureModal} picture={picture} />
        }
      </div>
    </div>
  );
};

export default DocTable;
