import { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import notification from '../../utility/notification';
import Select from 'react-select';
import { get } from '../../utility/fetch';
import CashBook from './CashBook';

const months = [
    {
        label: 'January',
        value: 'January'
    },
    {
        label: 'February',
        value: 'February'
    },
    {
        label: 'March',
        value: 'March'
    },
    {
        label: 'April',
        value: 'April'
    },
    {
        label: 'May',
        value: 'May'
    },
    {
        label: 'June',
        value: 'June'
    },
    {
        label: 'July',
        value: 'July'
    },
    {
        label: 'August',
        value: 'August'
    },
    {
        label: 'September',
        value: 'September'
    },
    {
        label: 'October',
        value: 'October'
    },
    {
        label: 'November',
        value: 'November'
    },
    {
        label: 'December',
        value: 'December'
    }
]

const CashBookModal = ({ type, isDFA, paymentVoucher, schedule, closeModal, office }) => {

    const [banks, setBanks] = useState([]);
    const [bankName, setBankName] = useState({})
    const [monthName, setMonthName] = useState({})
    const [yearName, setYearName] = useState({})
    const [showCashBookModal, setShowCashBookModal] = useState(false)




    const years = [
        {
            label: '2023',
            value: '2023'
        },
        {
            label: '2024',
            value: '2024'
        }
    ]



    const validateFields = () => {

        let error = "";

        // if (!bankName.label || bankName.label == "") {
        //     error = "Please Select a bank"
        //     return error;
        // }
        if (!monthName.value || monthName.value == "") {
            error = "Please Select a month"
            return error;
        }
        if (!yearName.value || yearName.value == "") {
            error = "Please Select a year"
            return error;
        }

        return error;
    }



    const getBank = async () => {
        const response = await get("/Ledger/Bank");
        if (response.status === "success") {
            setBanks(response?.data);
        }
        else {
        }
    }

    const viewPreview = () => {
        const error = validateFields()
        if (error !== "") {

            notification({
                title: 'Validation Error',
                type: 'error',
                message: error
            });
        }
        else {
            setShowCashBookModal(true);
        }
    }


    useState(() => {
        getBank();
    }, [])

    return (
        <div className="overlay m-t-20 ">
            <IoMdClose className="close-btn pointer" onClick={() => closeModal()} />
            <div style={{ width: '80%', margin: '0 auto' }} >
                <div className=" no-padding">
                    <div
                        className="m-t-10 p-t-10 flex-h-center modal-content-large"
                        style={{
                            boxShadow: '0px 2px 6px #0000000A',
                            // backgroundColor: 'white'
                        }}
                    >

                     {!isDFA &&  <>
                            <p className='m-t-20'>Select CashBook Bank:</p>
                            <Select
                                label="Select Payment Bank"
                                color='rgba(120,120,120,0.8)'
                                className='m-b-10 w-100'
                                onChange={(value) => {
                                    setBankName(value);
                                }}
                                value={bankName}
                                options={banks.map((m) => ({
                                    label: m.description,
                                    value: m.id,
                                }))}
                            />
                        </>}


                        <p className='m-t-20'>Select CashBook Month:</p>
                        <Select
                            label="Select Payment Bank"
                            color='rgba(120,120,120,0.8)'
                            className='m-b-10 w-100'
                            onChange={(value) => {
                                setMonthName(value);
                            }}
                            value={monthName}
                            options={months}
                        />

                        <p className='m-t-20'>Select CashBook Year:</p>
                        <Select
                            label="Select Payment Bank"
                            color='rgba(120,120,120,0.8)'
                            className='m-b-10 w-100'
                            onChange={(value) => {
                                setYearName(value);
                            }}
                            value={yearName}
                            options={years}
                        />

                        <button className='btn btn-large w-100' onClick={viewPreview}>Generate</button>
                    </div>
                </div>
            </div>
            {
                showCashBookModal &&
                <CashBook
                    office={office}
                    paymentVoucher={paymentVoucher}
                    month={monthName?.value}
                    year={yearName?.value}
                    bank={bankName?.label}
                    closeModal={() => setShowCashBookModal(false)}
                    isDFA={isDFA}
                />
            }
        </div >
    )
}

export default CashBookModal;