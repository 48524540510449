/* eslint-disable max-len */
import React, { useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import { UserInfoContext } from '../../contexts';
import { post } from '../../utility/fetch';
import { post as postExp } from '../../utility/fetchExpense';
import { post as postBud } from '../../utility/fetchBudget';
import coatOfArm from '../../assets/images/coat-of-arm.png';
import Footer from '../layouts/Footer';
import notification from '../../utility/notification';

const Home = (props) => {
  const { setCurrentUser } = useContext(UserInfoContext);

  const getExpenseToken = async() =>{
    try {
      const emp = new URLSearchParams(props.location.search).get('emp');
      const base = new URLSearchParams(props.location.search).get('base');
      const res = await postExp(
        `/api/Auth/authorization?urltoken=${emp}`
      );
      console.log(res)
      if (res.code === 1) {
        sessionStorage.setItem('tokenExp', res.token);
        localStorage?.setItem("mda", res?.mda);
        getBudgetToken();
        // props.history.push('/treasuryops');
      }
    } catch (error) {
      localStorage.removeItem('USER_INFO');
      notification({
        title: 'ACCESS DENIED',
        message: 'Sorry, This user does not have access to this application. Please Contact Admin',
        type: 'warning',
      });
    }
  }

  const getBudgetToken = async() =>{
    try {
      const emp = new URLSearchParams(props.location.search).get('emp');
      const base = new URLSearchParams(props.location.search).get('base');
      const res = await postBud(
        `/Auth/authorization?urltoken=${emp}`
      );
      console.log(res)
      if (res.statusCode === "00") {
        sessionStorage.setItem('budgetToken', res?.result?.token);
        // localStorage?.setItem("mda", res?.mda)
        props.history.push('/treasuryops');
      }
    } catch (error) {
      localStorage.removeItem('USER_INFO');
      notification({
        title: 'ACCESS DENIED',
        message: 'Sorry, This user does not have access to this application. Please Contact Admin',
        type: 'warning',
      });
    }
  }




  


  const makePostRequest = async () => {
    const baseURL = new URLSearchParams(props.location.search).get('base');
    try {
      const emp = new URLSearchParams(props.location.search).get('emp');
      const base = new URLSearchParams(props.location.search).get('base');
      const { status, data } = await post(
        `/Authentication/AccessToken?Token=${emp}`
      );
      if (status === 'success') {
        sessionStorage.setItem('token', data.token);
        localStorage.setItem("baseURL", base)
        localStorage.setItem("homeLink", data.homeLink)
        localStorage.setItem('USER_INFO', JSON.stringify(data));
        Cookies.set('homeLink', data.homeLink);
        // Cookies.set('token', data.token);
        Cookies.set('userId', data.userID);
        Cookies.set('name', data.name);
        Cookies.set('access', data.accesslevel);
        localStorage.setItem("accessLevel", data?.accessLevelId)
        localStorage.setItem("isHead", data?.isHead)
        localStorage.setItem("mdaId", data?.mdaId)
        Cookies.set('tokenExist', true);

        setCurrentUser(data);
        await getExpenseToken();
        // await getBudgetToken();
        
        
      }
    } catch (error) {
      localStorage.removeItem('USER_INFO');
      notification({
        title: 'ACCESS DENIED',
        message: 'Sorry, This user does not have access to this application. Please Contact Admin',
        type: 'warning',
      });
    }
    // setTimeout(() => window.location.assign("/"), 6000);
  };

  useEffect(() => {
    makePostRequest();
  }, []);

  return (
    <div className="w-100">
      <div className="banner">
        <div className="login">
          <div className="coat-of-arm">
            <img src={coatOfArm} alt="" />
            <h2>EDO STATE</h2>
            <h4>GOVERNMENT</h4>
          </div>
          <div className="form">Loading...</div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
