import Cookies from 'js-cookie';

export const logout = async () => {
  const link = localStorage.getItem('homeLink')
  sessionStorage.removeItem('token');
  localStorage.removeItem("requisitionNumber")
  localStorage.removeItem("approvedAmount")
  localStorage.removeItem("totalAmount")
  localStorage.removeItem("expenseType")
  localStorage.removeItem('USER_INFO');
  localStorage.removeItem('homeLink');
  localStorage.removeItem("requisitionNumber")
  localStorage.removeItem("approvedAmount")
  localStorage.removeItem("totalAmount")
  localStorage.removeItem("expenseType")
  localStorage.removeItem("expenseId")
  Cookies.set('tokenExist', false);
  Cookies.set('fullname', null);
  Cookies.set('role', null);

  window.location.assign(link || '/');
};
