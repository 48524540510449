import { useEffect, useState } from "react";
import { del, get } from "../../utility/fetch";
import { numberWithCommas } from "../../utility/general";
import Select from "react-select";
import { Pagination } from "antd";
import { IoMdAdd } from "react-icons/io";
import { BiEdit } from "react-icons/bi";
import { MdDelete, MdEdit } from "react-icons/md";
import notification from "../../utility/notification";
import BankBalanceModal from "../layouts/BankBalanceModal"
import {Tooltip} from "antd";

const tableHeaders = [
    'Bank',
    'Account Number',
    'Account Name',
    'Account Type',
    'Balance',
    'Action'
];


const BalanceSettings = () => {
    const [filteredRequest, setFilteredRequests] = useState([]);
    const [allHistory, setAllHistory] = useState([])
    const [banks, setBanks] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [bankId, setBankId] = useState(0);
    const [accountId, setAccountId] = useState(0);
    const [dateFrom, setDateFrom] = useState(0);
    const [dateTo, setDateTo] = useState(0);
    const [showTable, setShowTable] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [showBankBalanceModal, setShowBankBalanceModal] = useState(false)
    const [updateData, setUpdateData] = useState(null)


    const getBanks = async () => {
        setIsLoading(true)
        let res = await get("/Ledger/Bank")
        if (res?.status === "success") {

            setBanks(res?.data?.map((item) => (
                {
                    label: item?.description,
                    value: item?.id
                }
            )))

            setIsLoading(false)
        }
    }

    // const getAccounts = async (id) => {
    //     setIsLoading(true)
    //     let res = await get(`/Ledger/Account/Bank/${id}`)
    //     if (res?.status === "success") {
    //         setAccounts(res?.data?.map((item) => (
    //             {
    //                 label: item?.beneficiary,
    //                 value: item?.id
    //             }
    //         )))
    //         setIsLoading(false)
    //     }
    // }

    const getHistory = async () => {
        setIsLoading(true)
        let res = await get("/Ledger/History");
        if (res?.status === "success") {
            setFilteredRequests(res?.data);
            setAllHistory(res?.data);
        }
        setIsLoading(false)
    }

    const getAccounts = async () => {
        setIsLoading(true)
        let res = await get("/Ledger/Account");
        if (res?.status === "success") {
            setFilteredRequests(res?.data);
            // setAllHistory(res?.data);
        }
        setIsLoading(false)
    }

    const getHistoryRange = async (from, to) => {
        setIsLoading(true)
        let res = await get(`/Ledger/History/Range?dateFrom=${from}&dateTo=${to}`);
        if (res?.status === "success") {
            setFilteredRequests(res?.data)
        }
        setIsLoading(false)
    }

    const getBankHistory = async (id) => {
        setIsLoading(true)
        let res = await get(`/Ledger/History/Bank/${id}`);
        if (res?.status === "success") {
            setFilteredRequests(res?.data)
        }
        setIsLoading(false)
    }

    const getAccountHistory = async (id) => {
        setIsLoading(true)
        let res = await get(`/Ledger/History/Account/${id}`);
        if (res?.code === 1) {
            setFilteredRequests(res?.data)
        }
        else {
            setFilteredRequests([])
        }
        setIsLoading(false)
    }

    const handleAllHistory = () => {
        setFilteredRequests(allHistory)
        setShowTable(!showTable)
    }

    const handleUpdate = (data) =>{
        setUpdateData(data);
        setShowBankBalanceModal(true);
    }

    const deleteAccount = async(id) => {
        let res = await del(`/Ledger/Account/${id}`)
        if(res?.status === "success"){
            notification({
                type:"success",
                message:res?.message
            })
            getAccounts();
        }
        else{
            notification({
                type:"danger",
                message:res?.message
            })
        }
        console.log(res)
    }


    useEffect(() => {
        getBanks();
        getAccounts();
    }, [])

    return (
        <div>
            <div>
                <button onClick={() => setShowBankBalanceModal(true)} className="btn m-b-20"> <div className="flex flex-h-center"><IoMdAdd color="white" size={20} /> <span className="m-l-10">Add Account</span></div></button>
            </div>
            <div>
                <table className="table-view">
                    <thead className="">
                        <tr className="tr-bordered">
                            {tableHeaders.map((th, index) => (
                                <th key={index} className="th th-bordered">
                                    <p>{th}</p>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="table-body">
                        {filteredRequest?.map((item) => (
                            <tr role="presentation" key={item?.id}>
                                <td className="td p-r td-bordered">
                                    {item?.bank}
                                </td>
                                <td className="td p-r td-bordered">
                                    {item?.accountNo}
                                </td>
                                <td className="td p-r td-bordered">
                                    {item?.beneficiary}
                                </td>
                                <td className="td p-r td-bordered">
                                    {item?.accountType}
                                </td>
                                <td className="td p-r td-bordered">
                                    {`₦${numberWithCommas(Number(parseFloat(item?.currentBalance)).toFixed(2))}`}
                                </td>
                                <td className="td p-r td-bordered">
                                    <div>
                                       <Tooltip title="Edit"><MdEdit onClick={( ) => handleUpdate(item)} className="pointer" size={20} /></Tooltip> 
                                      <Tooltip title="Delete"><MdDelete onClick={()=>deleteAccount(item?.id)} className="m-l-10 pointer" size={20} color="red "/></Tooltip>  
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div style={{ marginTop: '20px' }}>
                <Pagination
                    showSizeChanger
                    // pageSize={pageSize}
                    pagination={true}
                    // onShowSizeChange={paginationChangeHandler}
                    // current={pageNumber}
                    // total={paginationLength}
                    // showTotal={true}
                    // onChange={paginationChangeHandler}
                    // pageSizeOptions={pageSizeOptions}
                    responsive
                />
            </div>
            {showBankBalanceModal && <BankBalanceModal closeModal={()=>setShowBankBalanceModal(false)} getAccounts={getAccounts}  updateData={updateData} setUpdateData={setUpdateData}/>}
        </div >
    )
}

export default BalanceSettings;