import "./spinner.css";

const Spinner = ({ color = "2px solid grey" }) => {
  return (
    <div
      className="loader"
      style={{
        borderTop: color,
      }}
    ></div>
  );
};

export default Spinner;
