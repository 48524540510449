import React, { useEffect, useState } from 'react';
import useMdaData from '../../utility/useMda';
import TextareaInput from '../inputs/TextareaInput';
import TextInput from '../inputs/TextInput';
import Select from "react-select";
// import axios from 'axios';
import Cookies from 'js-cookie';
import { get } from '../../utility/fetch';

const BASE_API_URL = `https://edogoverp.com/services/api`;

const SelectApprovers = ({
    setPaymentVoucher,
    paymentVoucher,
    mdaLabel,
    employeeLabel,
    setEmployeeName,
    handleApproverId,

}) => {


    const [mdaList, setMdaList] = React.useState([]);
    const [employees, setEmployees] = React.useState([]);
    const [employeeName, setemployeeName] = React.useState([]);
    const [recipientMda, setrecipientMda] = React.useState([]);
    const [approverId, setApproverId] = React.useState(0);
    const [approvers, setApprovers] = React.useState([]);

   
    const clearApprovers = () => {
        setemployeeName([]);
        setrecipientMda([]);
    };

    React.useEffect(() => {
        getApproversMDAs();
    }, [])


    const getEmployeesFromMda = async (id) => {
        
          const response = await get(`/Staff/${id}`)
            if(response.status === 'success'){
                
                // this.setState({
                const roleList = response?.data.map((item) => ({
                    name: `${item.fullName}`,
                    value: item.id,
                }));
                setEmployees(
                    roleList.filter((item) => {
                        return !item?.name.includes(Cookies.get("fullname"));
                    })
                );
            };
    };

    const getApproversMDAs = async () => {
        const response = await get(`/Mdas`)
        if(response.status === 'success'){
           
            // this.setState({
            const roleList = response?.data.map((item) => ({
                name: `${item.name}`,
                value: item.id,
            }));
            setMdaList(roleList);

        };
    };

  

    React.useEffect(() => {
        setApproverId(
            {
                approverID: approvers?.value,
            }
        );
    }, [approvers]);

    return (
        <div>
            <div >
                <div className='m-b-10 w-100 form-group '>
                    <label>{mdaLabel}</label>
                    <Select
                        label='Select Approvers MDA'
                        color='rgba(120,120,120,0.8)'
                        className='m-b-10 w-w00'
                        onChange={(value) => {
                            setrecipientMda(value);
                            getEmployeesFromMda(value.value);
                        }}
                        value={recipientMda}
                        options={mdaList.map((m) => ({
                            label: m.name,
                            value: m.value,
                        }))}
                    />

                </div>
                <div className='m-b-10 w-100 form-group '>
                    <label>{employeeLabel}</label>
                    <Select
                        placeholder='Select Approvers'
                        color='rgba(120,120,120,0.8)'
                        className='m-b-10 w-w00'
                        onChange={(value) => {
                            setemployeeName(value);
                            handleApproverId(value);
                            setApprovers(value);
                        }}
                        value={employeeName || approvers}
                        options={employees?.map((m) => ({
                            label: m.name,
                            value: m.value,
                        }))}
                    />

                </div>
            </div>
        </div>
    )
}

export default SelectApprovers;