import { useContext, useMemo } from 'react';
import { UserInfoContext } from '../contexts';
import useMdaData from '../utility/useMda';

const useFilteredData = (data, key = 'id') => {
  const { currentUser } = useContext(UserInfoContext);
  const mdas = useMdaData();
  const userMda = mdas.find((mda) => mda.id === currentUser?.mdaId);
  const filterKey = key === 'id' ? 'mdaId' : key;
  const exportData = useMemo(() => {
    if (currentUser?.accessLevelId === 1) {
      return data.filter((data) => data?.[filterKey] === userMda?.[key]);
    } else {
      return data;
    }
  }, [data, mdas]);

  return exportData;
};

export default useFilteredData;
