/* eslint-disable react/destructuring-assignment */
import React from 'react';

const BudgetInput = (props) => {
  return (
    <div
      className={`${props.className}`}
      style={{
        padding: '.8rem',
        borderBottom: '1px solid rgba(108,108,108,.4)'
      }}
    >
      <p style={{ fontSize: '10px' }}>{props.label}</p>
      <h3>{props.value}</h3>
    </div>
  );
};

export default BudgetInput;
