/* eslint-disable react/destructuring-assignment */
import React from 'react';

const SelectInput2 = (props) => {
  const options =
    props.options &&
    [{ name: 'Select item', value: '', id: 0 }, ...props.options].map(
      (option) =>
        typeof option === 'object' ? (
          <option
            // onClick={() => {
            //   option.id !== 0 && console.log("option id", option.id);
            //   //  option.id !== 0 && props.selectOption(id);
            // }}
            id={option.id ? option.id : null}
            value={
              option.id &&
              option.id.toString().toLowerCase() === 'select item...'
                ? ''
                : option.id
            }
            key={option.name}
          >
            {option.name}
          </option>
        ) : (
          <option
            value={
              option && option.toString().toLowerCase() === 'select item...'
                ? ''
                : option
            }
            key={option}
          >
            {option}
          </option>
        )
    );
  return (
    <div className={`${props.className} form-group`}>
      <label htmlFor={props.name}>
        {props.label}&nbsp;
        {props.required && <span style={{ color: '#ff6242' }}>*</span>}
      </label>
      <select
        name={props.name}
        id={props.name}
        value={props.id}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
      >
        {options}
      </select>
    </div>
  );
};

export default SelectInput2;
