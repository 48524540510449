import { MdDelete, MdEdit } from "react-icons/md";
import { getMonth, numberWithCommas } from "../../utility/general";
import { Tooltip } from "antd";
import CashBudgetEditor from "./CashBudgetEditor";
import { useState } from "react";
import { AiFillDelete, AiOutlineDelete } from "react-icons/ai";

const CashBudgetFormCopy = ({ data, setPayload }) => {
    const [dataIndex, setDataIndex] = useState();
    const [showModal, setShowModal] = useState(false);

    const handleEdit = (index) => {
        setDataIndex(index);
        setShowModal(true);
    }

    const handleDelete = (id) => {
      
       let newData = data?.filter((data) => data?.id !== id);
        setPayload(newData)
       
    }

    const closeModal = () => {
        setShowModal(false);
    }

  

    return (
        <div className="form-bg-shadow bg-white p-40 w-75">
            <p>The Summarized Cash Budget for the month of {getMonth(data[0]?.month)} {data[0]?.year} is as analyzed below:</p>
            {
                data?.map((item, index) => (
                    <div key={index} className="flex m-t-20">
                        <h3 className="flex-basis-5 ">{index + 1}</h3>
                        <h3 className="flex-basis-20 ">{item?.economicCode}-</h3>
                        <h3 className="flex-basis-40 ">{item?.economicCodeDescription}</h3>
                        <h3 className="flex-basis-25 ">N{item?.amount}</h3>
                        <Tooltip title="Edit Entry"><MdEdit style={{ marginRight: "16px" }} size={25} color="gray" className="pointer" onClick={() => handleEdit(index)} /></Tooltip>
                        <Tooltip title="Remove Entry"><AiOutlineDelete   style={{ marginRight: "16px", color:"red" }}  size={25} color="gray" className="pointer" onClick={() => handleDelete(item?.id)} /></Tooltip>
                    </div>
                ))
            }

            <div className="flex m-t-20">
                <h2 className="flex-basis-65 right-text p-r-60">TOTAL:</h2>
                <h2 className="underline">{numberWithCommas(Number(parseFloat(data?.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue?.amount), 0))).toFixed(2))}</h2>
            </div>

            {
                showModal && <CashBudgetEditor index={dataIndex} data={data} setPayload={setPayload} closeModal={closeModal} />
            }
        </div>
    )
}
export default CashBudgetFormCopy;