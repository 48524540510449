/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { DatePicker } from 'antd';

const DateComponent = (props) => {
  return (
    <div className={props.className}>
      <div>
        <label htmlFor={props.name}>{props.label}</label>
      </div>

      <DatePicker format={'DD-MM-YYYY'} {...props} />
    </div>
  );
};

export default DateComponent;
