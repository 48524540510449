import React, { useContext } from 'react';
import { GoLocation } from 'react-icons/go';
import { UserInfoContext } from '../../contexts/index';

const Greeting = () => {
  const { currentUser } = useContext(UserInfoContext);
  return (
    <div className=" flex flex-v-center space-between m-b-20">
      <div>
        <h5 className="m-b-3">Good Day</h5>
        <h3 className="m-b-3">{currentUser.name || 'Osagie Osaigbovo'}</h3>
        <h5>{currentUser?.role || 'Officer 1, Ministry of Education'}</h5>
      </div>
      <div className="flex">
        <GoLocation
          className="icon"
          style={{ fontSize: '30px', color: '#41821e' }}
        />
      </div>
    </div>
  );
};

export default Greeting;
