import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Badge, Pagination } from 'antd';
import { IoMdClose } from 'react-icons/io';
import { uploadDocument } from '../../utility/otherApis';
import TextareaInput from '../inputs/TextareaInput';
import TextInput from '../inputs/TextInput';
import DatePicker from '../inputs/DatePicker';
import { Link } from 'react-router-dom';
import { get, patch } from '../../utility/fetch';
import useMdaData from '../../utility/useMda';
import notification from '../../utility/notification';
import PageCards from '../layouts/PageCards';
import FilterBox from '../layouts/FilterBox';
import moment from 'moment';
import Modal from '../layouts/modal';

// Write file input field and ref to trigger the input field

const ColumnHeaders = [
  'Memo/Payment Title',
  'Approved Date',
  'Amount',
  'Action'
];

let onboardedStaffMemo;

const SEQUENCE_NUMBER = 5;

const previousTransactionsTableHeaders = [
  'Payment ID#',
  'Amount',
  'Request Initiator',
  'Approval Date',
  'Payment Date'
];

const FilterBadge = ({ isActive, badgeCount, text, onClick }) => {
  return (
    <div className={`m-l-10 pointer  ${isActive ? 'filter_badge_active' : 'filter_badge_inactive'}`}>
      <Badge color="red" count={badgeCount}>
        <div
          onClick={() => onClick()}
          className={`flex-h-center filter_badge_text flex-v-center`}
        >
          {text}
        </div>
      </Badge>
    </div>
  );
};

const Mda = ({ history }) => {
  const [title, setTitle] = useState('');

  const fileInputRef = useRef(null);

  const [previousTransactions, setPrevioustRansaction] = useState([]);
  const [requestId, setRequestId] = useState();
  const [paymentAdviceInformation, setPaymentAdviceInformation] = useState({
    id: 0,
    bank: '',
    accountNo: '',
    expectedPaymentDate: ''
  });
  const [categoryData, setCategoryData] = useState([]);
  const [uploadingItemId, setUploadingItemId] = useState(0);
  const [isUploadingDocument, setIsUploadingDocument] = useState(false);
  const [url, setUrl] = useState(0);
  const [workflowPerItem, setWorkFlowPerItem] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showItem, setShowItem] = useState(null);
  const [paymentPools, setPaymentPool] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [limit, setLimit] = useState(20);
  const [current, setCurrent] = useState(1);
  const [workflowData, setWorkflowData] = useState([]);
  const [documentData, setDocumentData] = useState({
    name: '',
    description: '',
    files: []
  });
  const [activeFilter, setActiveFilter] = useState('Pending');
  let mdas = useMdaData();
  const [requestPaymentItem, setrequestPaymentItem] = useState([]);
  const [requestFixed, setFixedRequest] = useState([]);

  useEffect(() => {
    let filteredRequest = requestFixed.filter(req => !Boolean(req.approverId))
    if (activeFilter.toLowerCase() !== "pending") {
      filteredRequest = requestFixed.filter(req => Boolean(req.approverId))
    }
    setrequestPaymentItem(filteredRequest.reverse());
  }, [requestFixed, activeFilter]);

  const handleDocumentChange = async (e, id) => {
    const file = await handleFilesUpload(e.target.files[0]);
    if (file) {
      postPaymentAdvice(file, id);
    }
  };

  const handleSearch = (searchValue) => {
    if (!searchValue || !searchValue?.length) setrequestPaymentItem(requestFixed)

    const result = requestFixed.filter((item) => {
      const values = Object.values(item);
      for (let i = 0; i < values.length; i++) {
        if (values[i] && values[i].toString().toLowerCase().includes(searchValue.toLowerCase())) {
          return true;
        }
      }
      return false;
    });
    setrequestPaymentItem(result);
  };

  const filterByCategory = (catName) => {
    const category = categoryData.find(cat => cat.description === catName)
    console.log(category)
    const res = requestFixed.filter(item => item?.treasuryRequestObject?.treasuryCategorySetupId === category.id)
    setrequestPaymentItem(res)
  }


  const fetchSequenceCategory = async () => {
    try {
      const { status, data } = await get(`/Setup/Category`, {}, true);
      if (status === 'success') {
        console.log(data, "Sequence data")
        setCategoryData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilesUpload = async (file) => {
    setIsUploadingDocument(true);
    if (!file) return;
    const { type, size } = file;
    console.log(type, size, file);

    const supportedTypes = [
      'jpeg',
      'jpg',
      'gif',
      'png'
    ];
    const fileType = type.slice(type.indexOf('/') + 1);

    if (!supportedTypes.includes(fileType)) {
      notification({
        title: 'Invalid File Type',
        message:
          'Invalid file format. Supported file types have to be image (jpeg, png and gif)',
        type: 'error'
      });
      return;
    }
    if (size / 1024 > 500000) {
      notification({
        title: 'File Too Large',
        message: 'The file size must not be more than 500MB',
        type: 'error'
      });
      return;
    }

    setIsSubmitting(true);

    notification({
      title: 'Uploading Documents',
      message: 'Currently uploading the attached document...',
      type: 'info'
    });
    const body = new FormData();
    body.append('files', file);

    const response = await uploadDocument(body);
    setIsSubmitting(false);
    setIsUploadingDocument(false);
    if (response && response.status === 200) {
      if (response.data.code === 1) {
        return response.data.doclink;
      } else {
        notification({
          title: 'Document Upload Failed',
          message: response.data.message,
          type: 'error'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message:
          'Something went wrong while uploading the documents for this request. Please, try again later.',
        type: 'error'
      });
    }
  };

  const getWorkflow = async (id) => {
    try {
      const { status, data } = await get(
        `/GetTreasuryRequestWorkflow/Details/${id}`
      );
      if (status === 'success') {
        setWorkflowData(data);
        setPrevioustRansaction(data.paymentResponse);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postPaymentAdvice = async (checkUrl, paymentId) => {
    try {
      const { id } = paymentAdviceInformation;

      const paymentPoolId = paymentPools.filter(
        (pool) => pool.paymentId === id || pool.paymentId === paymentId
      )[0]?.id;
      if (!paymentPoolId) {
        notification({
          title: `This item has no payment pool`,
          message: 'This Item has no payment pool',
          type: 'error'
        });
        return;
      }
      let payload = Object.fromEntries(
        Object.entries(paymentAdviceInformation).filter(([_, v]) => v != null)
      );
      payload = {
        ...payload,
        id: paymentPoolId
      }
      if (checkUrl) {
        payload.checkUrl = checkUrl
      }
      const { status, data } = await patch('/CreateTreasuryPaymentAdvice', payload);
      if (status === 'success') {
        setShowModal(false)
        setShowItem(null)
        getrequestPaymentPool()
        notification({
          title: `Payment Advice Updated`,
          message: 'Payment Advice Updated Successfully',
          type: 'success'
        });
      }

    } catch (error) {
      notification({
        title: `Failed to Create Payment Advice`,
        message: "Couldn't Create Payment Advice",
        type: 'error'
      });
      console.log(error);
    }
    setShowItem(null)
  };

  const getPaymentDetails = async (id) => {
    try {
      const { status, data } = await get(
        `/GetTreasuryPaymentPool/Payment/${id}`
      );
      if (status === 'success') {
        setWorkFlowPerItem(data.requestWorkflowResponse);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getrequestPaymentItem = async () => {
    try {
      const { status, data } = await get(
        '/GetTreasuryPaymentFiltered',
        {
          PageSize: limit,
          PageNumber: current,
          TreasurySequenceStateId: 5,
          addedToPaymentPool: 1
        },
        true
      );
      if (status === 'success') {
        setFixedRequest(data?.items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const paginationChangeHandler = (current, pageSize) => {
    setLimit(pageSize);
    setCurrent(current);
  };



  useEffect(() => {
    window.scrollTo(0, 0);
    getrequestPaymentItem();
    fetchSequenceCategory();
  }, [current, limit]);

  const getPaymentItem = (id) => {
    const item = requestFixed.find((req) => req.id === id);
    return item;
  };

  const getrequestPaymentPool = async () => {
    try {
      const { status, data } = await get(
        '/GetTreasuryPaymentPoolFiltered',
        {
          PageSize: limit,
          PageNumber: current,
          TreasurySequenceStateId: 3,
        },
        true
      );
      if (status === 'success') {
        setPaymentPool(data.items);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const performPageMetaActions = () => {
    window.scrollTo(0, 0);
    const title = 'Management Account';
    document.title = `EDSG - ${title}`;
    setTitle(title);
    getrequestPaymentItem();
  };
  useEffect(() => {
    getrequestPaymentPool();
    performPageMetaActions();
  }, []);

  const filterList = useMemo(() => {
    return [
      {
        text: 'Pending',
        badgeCount: requestFixed?.filter((item) => !item.approverId).length,
        isActive: activeFilter === 'Pending',
        onClick: () => setActiveFilter('Pending')
      },
      {
        text: 'Approved',
        badgeCount: requestFixed?.filter((item) => item.approverId)
          .length,
        isActive: activeFilter === 'Approved',
        onClick: () => setActiveFilter('Approved')
      }
    ];
  }, [activeFilter, requestFixed]);

  console.log("check url", paymentAdviceInformation.checkUrl)
  const renderList = () => {
    return (
      <div className="col-9 flex flex-col">
        <div className="w-100 m-t-20 p-x-10 p-y-10 bg_green  space-between flex   flex-v-center flex-h-end">
          <div className="bold-text">Approved Payment Log</div>
          <div className=" flex   flex-v-center flex-h-end">
            {filterList.map((filter) => (
              <FilterBadge {...filter} />
            ))}
          </div>
        </div>
        <div className="table-view">
          <div className="table-header">
            <div className="table-row bg-grey">
              {ColumnHeaders.map((header) => (
                <div className="th th-bordered ">
                  <p className="">{header}</p>
                </div>
              ))}
            </div>
          </div>
          <tbody className="table-body">
            {requestPaymentItem.map((item) => (
              <tr role="presentation">
                <td className="td p-r td-bordered">
                  <Link
                    to={`/treasuryops/treasury_request/${item.treasuryRequestId}?sequence_number=${SEQUENCE_NUMBER}`}
                  >
                    <span style={{ cursor: 'pointer' }} className="underline">
                      {item.name}
                    </span>
                  </Link>
                </td>
                <td className="td p-r td-bordered">
                  {item?.treasuryRequestObject
                    ? moment(item?.treasuryRequestObject?.lastUpdated).format(
                      'MMM Do YY'
                    )
                    : '-'}
                </td>

                <td className="td p-r td-bordered">
                  <div
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => {
                      setShowModal(true);
                      getWorkflow(item.treasuryRequestId);
                    }}
                  >
                    {item.amount
                      ? (item.amount * item.quantity)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      : '-'}
                  </div>
                </td>

                {/* <td className="td p-r td-bordered">{item.owner || '-'}</td> */}
                {/* <td className="td p-r td-bordered">{item.remarks || '-'}</td> */}
                <td className="td flex p-r td-bordered">
                  <button
                    style={{ cursor: 'pointer', height: '30px', width: '50%' }}
                    onClick={() => {
                      setShowItem(item);
                      let pool = paymentPools.filter(
                        (pool) => pool.paymentId === item.id
                      )[0];
                      if (!pool) pool = {};
                      console.log("poool", pool)
                      setPaymentAdviceInformation(() => ({
                        bank: '',
                        accountNo: '',
                        expectedPaymentDate: '',
                        ...pool,
                        id: item.id
                      }));
                      getPaymentDetails(item.id);
                      getWorkflow(item.treasuryRequestId);
                    }}
                    className="table_button m-r-10"
                  >
                    View Details
                  </button>
                  <button
                    onClick={() => {
                      setUploadingItemId(item.id);
                      fileInputRef?.current?.click();
                    }}
                    class="custom-file-upload"
                  >
                    Upload Cheque
                  </button>
                </td>
              </tr>
            ))}
          </tbody>

        </div>
        <div style={{ marginTop: '20px' }}>
          <Pagination
            pageSize={limit}
            // onShowSizeChange={paginationChangeHandler}
            current={current}
            total={requestPaymentItem?.length ?? 0}
            // showTotal={true}
            onChange={paginationChangeHandler}
            // pageSizeOptions={pageSizeOptions}
            responsive
          />
        </div>
      </div>
    );
  };

  const getMdaName = (id) => {
    if (!id) return;
    const d = mdas.find((mda) => mda.id === id);
    if (d) return d?.name;
  };

  return (
    <div className="w-100">
      <input
        onChange={(e) => handleDocumentChange(e, uploadingItemId)}
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
      />
      <div className="w-100">
        <div className="w-100 flex space-between flex-v-center">{title}</div>

        <div className="w-100 flex">
          <div className="flex-col col-3 m-r-10 m-t-20">
            <div className="flex ">
              <PageCards
                cardTitle=" Payment Items"
                count={requestFixed.length}
                // onClick={() => }
                isActive={false}
              />
            </div>
            <FilterBox
              className="m-t-10"
              title={'Search for a payment'}
              onSearch={handleSearch}
              type="search"
              placeholder="enter search text"
            />
            <FilterBox
              className={'filter_box_border_b'}
              title={'Filter By Category'}
              type="select"
              onChange={filterByCategory}
              options={categoryData.map(cat => cat.description)}
              placeholder="Filter By Category"
            />
            <FilterBox
              className={'filter_box_border_b'}
              title={'Budget Item'}
              type="select"
              options={['Option One']}
              placeholder="enter search text"
            />
          </div>
          {renderList()}
        </div>
      </div>
      <Modal onClose={() => setShowModal(false)} visible={showModal}>
        <div
          style={{
            boxShadow: '0px 2px 6px #0000000A',
            backgroundColor: 'white'
            // padding: '20px'
          }}
        >
          <div className="w-100  p-x-10  bg_green  space-between flex   flex-v-center flex-h-end accordion">
            <div className="bold-text">Approval #ID: {requestId}</div>
          </div>
          <table className="table-view">
            <thead className="">
              <tr className="tr-bordered">
                {previousTransactionsTableHeaders.map((th, index) => (
                  <th key={index} className="th th-bordered">
                    <p>{th}</p>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="table-body">
              {previousTransactions.map((item) => {
                const currItem = getPaymentItem(item.id);
                console.log(currItem);
                return (
                  <tr role="presentation">
                    <td className="td p-r td-bordered">{item.paymentId}</td>
                    <td className="td p-r td-bordered">
                      {' '}
                      {item.amount
                        ? (item.amount * item.quantity)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : 'N/A'}
                    </td>
                    <td className="td p-r td-bordered">
                      {currItem?.treasuryRequestObject?.requestInitiator}
                    </td>
                    <td className="td p-r td-bordered">
                      {currItem?.treasuryRequestObject
                        ? moment(
                          item?.treasuryRequestObject?.lastUpdated
                        ).format('MMM Do YY')
                        : 'N/A'}
                    </td>
                    <td className="td p-r td-bordered">
                      {currItem?.paymentDate &&
                        moment(item?.paymentDate).format('MMM Do YY')}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal>
      {showItem && (
        <div className="overlay">
          <IoMdClose
            className="close-btn pointer"
            onClick={() => setShowItem(null)}
          />

          <div
            style={{
              boxShadow: '0px 2px 6px #0000000A',
              backgroundColor: 'white',
              marginTop: '300px',
              width: '50%',
              margin: '0 auto',
              display: 'flex',
              flexDirection: 'column',
              padding: '20px'
            }}
          >
            <div className="w-100 bg_green  space-between flex   flex-v-center flex-h-end accordion">
              <div className="bold-text">Approval #ID: {requestId}</div>
            </div>
            <div className="w-100 m-t-10 flex">
              <TextInput
                className="w-100  m-b-10"
                name="requestTitle"
                label="Item Name"
                value={showItem?.name}
              // onChange={handleChange}
              />
              <TextInput
                className="w-100 m-b-10"
                name="requestTitle"
                label="Item Amount"
                value={showItem?.amount}
              // value={requestData.requestTitle}
              // onChange={handleChange}
              />
            </div>
            <div className="w-100 m-t-10 flex">
              <TextInput
                className="w-100 m-b-10"
                name="requestTitle"
                label="Beneficiary"
                disabled
                value={getMdaName(workflowPerItem?.mdaId)}
              // value={requestData.requestTitle}
              // onChange={handleChange}
              />
              <DatePicker
                className="w-100  m-b-10"
                name="paymentDate"
                label="Payment Date"
                value={
                  paymentAdviceInformation.expectedPaymentDate &&
                  moment(paymentAdviceInformation.expectedPaymentDate)
                }
                onChange={(d, dateString) => {
                  console.log()
                  setPaymentAdviceInformation({
                    ...paymentAdviceInformation,
                    expectedPaymentDate: moment(d).toISOString()
                  });
                }}
              />
            </div>
            <div className="w-100 m-t-10 flex">
              <TextInput
                className="w-100  m-b-10"
                name="bankName"
                label="Enter Bank Name"
                onChange={(e) =>
                  setPaymentAdviceInformation({
                    ...paymentAdviceInformation,
                    bank: e.target.value
                  })
                }
                value={paymentAdviceInformation.bank}

              // value={requestData.requestTitle}
              // onChange={handleChange}
              />
              <TextInput
                className="w-100  m-b-10"
                name="accountNo"
                onChange={(e) =>
                  setPaymentAdviceInformation({
                    ...paymentAdviceInformation,
                    accountNo: e.target.value
                  })
                }
                value={paymentAdviceInformation.accountNo}
                label="Enter Account Number"
              // value={requestData.requestTitle}
              // onChange={handleChange}
              />
            </div>
            <div className="w-100 m-t-10 flex">
              <TextareaInput
                className="w-100  m-b-10"
                name="comment"
                label="Requestor Comment"
                value={
                  paymentAdviceInformation.requestorsComment ||
                  paymentAdviceInformation.agComment
                }
                onChange={(e) =>
                  setPaymentAdviceInformation({
                    ...paymentAdviceInformation,
                    agComment: e.target.value
                  })
                }
              // value={requestData.requestTitle}
              // onChange={handleChange}
              />
              <TextareaInput
                className="w-100  m-b-10"
                name="comment"
                label="Commissioner's Comment"
                value={paymentAdviceInformation.commisionersComment}
                onChange={(e) =>
                  setPaymentAdviceInformation({
                    ...paymentAdviceInformation,
                    commisionersComment: e.target.value
                  })
                }
              // value={requestData.requestTitle}
              // onChange={handleChange}
              />
            </div>
            <div className="w-100 m-t-10 flex">
              {paymentAdviceInformation.checkUrl && <img
                style={{ width: '100%', height: '300px' }}
                className=" m-t-10 flex"
                alt="Check Url"
                src={paymentAdviceInformation.checkUrl}
              ></img>}
            </div>
            <div className="w-100 m-t-10 flex">
              <button
                onClick={() => postPaymentAdvice()}
                className="btn btn-large w-100"
              >
                Submit Advise
              </button>
            </div>
          </div>
        </div>
      )}

     
    </div>
  );
};

export default Mda;
