import React, { useEffect, useState, useContext } from 'react';
// import { Link } from 'react-router-dom';
// import { MdNotifications } from 'react-icons/md';
import { TiArrowSortedDown } from 'react-icons/ti';

import { UserInfoContext } from '../../contexts';

import UserAvater from '../../assets/images/user-avater.png';

import { formatFileUrl, getDeptName } from '../../utility/general';

const Header = ({ details = {}, navList = [] }) => {
  const { currentUser } = useContext(UserInfoContext);

  const [userPix, setUserPix] = useState('');
  const [imgHasError, setImgHasError] = useState(false);

  useEffect(() => {
    !imgHasError && setUserPix(formatFileUrl(currentUser.profilePicture));
  }, [imgHasError, details, currentUser.profilePicture]);

  const handleImgError = () => {
    setUserPix('');
    setImgHasError(true);
  };

  return (
    <header>
      <div className="content header-content space-between flex-v-center">
        <div className="header-left" />
        <div className="header-right flex flex-v-center">
          <div className="right-item">
            <ul className="nav-menu">
              {navList && navList.map((item) => <li key={item}>{item}</li>)}
            </ul>
          </div>
          <div className="right-item">
            <div
              role="presentation"
              onClick={() => { }}
              className="user-avater-details flex flex-v-center pointer"
            >
              <div className="m-r-5 right-text" style={{ paddingTop: '3px' }}>
                <p className="name">{currentUser?.name?.toUpperCase() || 'Osagie Osaigbovo'}</p>
                <div className="flex">
                  <p className="role">{currentUser?.role || 'Officer 1'}</p> &nbsp;
                  <p className="role">({getDeptName(localStorage.accessLevel) || 'Officer 1'})</p>
                </div>
              </div>
              <div className="flex">
                <img
                  onError={handleImgError}
                  src={userPix || UserAvater}
                  alt="user avater"
                />
              </div>
              <div className="flex arrow">
                <TiArrowSortedDown />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
