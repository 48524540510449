import { React, useState, useEffect } from "react";
import TextareaInput from "../inputs/TextareaInput";
import { IoMdClose } from "react-icons/io";
import LoaderSrc from "../../assets/svg/loading.svg";
import TextInput from "../inputs/TextInput";
import { stripCommas } from "../../utility/general";
import { numberWithCommas } from "../../utility/general";
import ReactSelect from "react-select";
import { get, post, put } from "../../utility/fetch";
import notification from "../../utility/notification";

const BankBalanceModal = ({ closeModal, updateData, setUpdateData, getAccounts, ...props }) => {
    const [disableBtn, setDisableBtn] = useState(false);
    const [banks, setBanks] = useState([])
    const [accounts, setAccounts] = useState([])
    const [accountType, setAccountType] = useState([])
    const [prefillData, setPrefillData] = useState({
        label:"",
        value:0
    })
    const [data, setData] = useState({
        bankId: 0,
        accountNo: "",
        beneficiary: "",
        accountTypeId: 0,
        currentBalance: 0
    })


    const handleChange = (e) => {

        setData((prev) => (
            { ...prev, [e.target.name]: e.target.value }
        ))


    }

    const getBanks = async () => {
        let res = await get("/ledger/Bank")
        if(res?.status === "success"){
            setBanks(res?.data?.map((item) => (
            {
                label: item?.description,
                value: item?.id
            }
        ))) 

        if (updateData) {
            preFillBankandAccount(res?.data?.map((item) => (
            {
                label: item?.description,
                value: item?.id
            }
        )))
        }
        }
       
    }

    const getAccountType = async () => {
        let res = await get("/ledger/AccountType")
        setAccountType(res?.data?.map((item) => (
            {
                label: item?.description,
                value: item?.id
            }
        )))
    }

    const getBankAccount = async (id) => {
        let res = await get(`/ledger/Account/Bank/${id}`)
        setAccounts(res?.data?.map((item) => ({
            label: item?.beneficiary,
            value: item?.id
        })))
    }


    const preFillBankandAccount = (banks) => {
        setData((prev) => (
            { ...prev, bankId: updateData?.bankId, beneficiary: updateData?.beneficiary }
        ))

        let bankData = banks?.find((item)=>{
            return item?.value === updateData?.bankId
        })

     
        setPrefillData(bankData)



    }
    // const handleAccounts = (id) =>{
    // }


    const submit = async () => {
        let payload;
        let res;

        if (updateData) {
            payload = {
                ...data,
                id: updateData?.id,
                currentBalance: Number(stripCommas(data?.currentBalance))
            }
        }
        else {
            payload = {
                ...data,
                currentBalance: Number(stripCommas(data?.currentBalance))
            }
        }

        if (updateData) {
            res = await put("/Ledger/Account", payload)

        }
        else {
            res = await post("/Ledger/Account", payload)
        }


        if (res?.status === "success") {

            notification(
                {
                    type: "success",
                    message: res?.message
                }
            )
            getAccounts();
            setUpdateData(null);
            closeModal();
        }
    }


    useEffect(() => {
        getBanks()
        getAccountType()

        
    }, [])


    return (
        <div
            className="overlay"
            style={{
                alignItems: "center",
                justifyContent: "center",
                padding: "40px",
                boxShadow: "0px 2px 6px #0000000A",
            }}
        >
            <div>
                <IoMdClose className="close-btn pointer" onClick={closeModal} />
                <div className="modal-box max-w-700 pd-10 ">
                    <p className=" m-t-20">Select Bank:</p>
                    <ReactSelect
                        options={banks}
                        value = {updateData ? prefillData : undefined}
                        disabled = {updateData ? true : false}
                        onChange={(value) => {
                            setData((prev) => (
                                { ...prev, bankId: value?.value }
                            )); getBankAccount(value?.value)
                        }
                        }
                        className="m-b-20 "
                    />

                    {/* {updateData && <p>Select Bank Account:</p>}
                    {updateData && <ReactSelect
                        options={accounts}
                        onChange={(value) => setData((prev) => (
                            { ...prev, beneficiary: value?.label }
                        ))}
                        className="m-b-20"
                    />} */}

                    <TextInput
                        label={"Enter Bank Account Name"}
                        name="beneficiary"
                        value={updateData !== null ? updateData?.beneficiary : null}
                        disabled={updateData}
                        onChange={handleChange}
                        className="m-b-20"
                    />

                    <p>Select Account Type:</p>
                    <ReactSelect
                        options={accountType}
                        onChange={(value) => setData((prev) => (
                            { ...prev, accountTypeId: value?.value }
                        ))}
                        className="m-b-20"
                    />

                    {/* <TextInput
                        label={"Enter Bank Account Name"}
                        name="beneficiary"
                        // value={}
                        onChange={handleChange}
                        className="m-b-20"
                    /> */}

                    <TextInput
                        label={"Enter Bank Account Number"}
                        name="accountNo"
                        // value={numberWithCommas(data?.currentBalance)}
                        onChange={handleChange}
                        className="m-b-20"
                    />
                    <TextInput
                        label={"Enter Bank Balance"}
                        name="currentBalance"
                        value={numberWithCommas(data?.currentBalance)}
                        onChange={handleChange}
                        className="m-b-20"
                    />
                    <button
                        className="btn btn-large p-r w-100 flex flex-v-center flex-h-center justify-content-center m-t-10"
                        onClick={() => {
                            // setDisableBtn(true);
                            submit();
                        }}
                        disabled={disableBtn}
                        style={{ height: "43px" }}
                    >
                        <p style={{ margin: "auto 15px auto" }}>Submit</p>
                        { disableBtn && <img src={LoaderSrc} alt="Loading..." /> }
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BankBalanceModal;
