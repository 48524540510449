import React, { useEffect, useState } from 'react';
import { getFirstLetters, numberWithCommas, stripCommas } from '../../utility/general';
import { IoMdClose } from 'react-icons/io';
import { get, put } from "../../utility/fetch";
import UpdateBalanceModal from "./UpdateBalanceModal";
import notification from '../../utility/notification';


export default function BankAccountsModal({ closeModal, }) {

    const [accounts, setAccounts] = useState([])
    const [showUpdateBalanceModal, setShowUpdateBalanceModal] = useState();
    const [data, setData] = useState()

    const getBankAccounts = async () => {
        let res = await get("/Ledger/Account")
        if (res?.status === "success") {
            setAccounts(res?.data);
        }
    }

    const updateAccountBalance = async (balance, data) => {
        let payload = {
            bankId: data?.bankId,
            accountNo: data?.accountNo,
            beneficiary: data?.beneficiary,
            accountTypeId: data?.accountTypeId,
            currentBalance: stripCommas(balance),
            id: data?.id
        }

        let res = await put("/Ledger/Account", payload)

        if (res?.status === "success") {
            notification({
                type: "success",
                message: "Successfully updated bank account."
            })
            getBankAccounts();
            closeUpdateModal()

        }
    }

    const closeUpdateModal = async () => {
        setShowUpdateBalanceModal(false);
    }

    const handleUpdateBalanceModal = (updateData) => {
        setData(updateData);
        setShowUpdateBalanceModal(true);
    }


    useEffect(() => {
        getBankAccounts();
    }, [])

    return (
        <div
            className="overlay"
            style={{
                alignItems: "center",
                justifyContent: "center",
                padding: "40px",
                boxShadow: "0px 2px 6px #0000000A",
            }}
        >
            <div>
                <IoMdClose className="close-btn pointer" onClick={closeModal} />
                <div className="modal-box w-80 pd-10 flex flex-h-center">
                    <div className="w-80 p-10 ">

                        {accounts?.length > 0 && accounts?.map((item) => (
                            <div className='flex space-between border-des flex-v-center m-t-20 m-b-20'>
                                <div className='flex-basis-10'>
                                    <p className='rounded'><span>{getFirstLetters(item?.bank)}</span></p>
                                </div>
                                <div className='flex-basis-20'>
                                    <h2>{item?.bank}</h2>
                                    <h3>{item?.accountNo}</h3>
                                </div>
                                <div className='flex-basis-20'>
                                    {item?.accountType}
                                </div>
                                <h1 className='flex-basis-20'>
                                    {` N${numberWithCommas(item?.currentBalance)}`}
                                </h1>
                                <div className='flex-basis-20'>
                                    <button onClick={() => handleUpdateBalanceModal(item)} className='btn'>
                                        Enter Balance
                                    </button>
                                </div>
                            </div>
                        ))}

                        {showUpdateBalanceModal && <UpdateBalanceModal closeModal={closeUpdateModal} submit={updateAccountBalance} data={data} />}

                    </div>
                </div>
            </div>
        </div>


    )

}