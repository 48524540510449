import React, { useEffect } from 'react';
import { get } from '../../utility/fetch';
import TextareaInput from '../inputs/TextareaInput';
import TextInput from '../inputs/TextInput';
import SelectApprovers from './SelectApprovers';
import Select from "react-select";


const ScheduleForm = ({
    schedule,
    setSchedule,
    paymentVoucher,
    scheduleEmployeeName,
    setScheduleEmployeeName
}) => {
    const [counterSigningOfficers, setCounterSigningOfficers] = React.useState([]);
    const [mdaList, setMdaList] = React.useState([]);
    const [counterSigningOfficerName, setCounterSigningOfficerName] = React.useState()

    useEffect(() => {
        getCounterSigningOfficer();
        getApproversMDAs();
        setSchedule(
            {
                ...schedule,
                station: paymentVoucher?.station,
                pvNo: 0,
                administrativeCode: paymentVoucher?.administrativeCode,
                date: paymentVoucher.date,
                description: paymentVoucher?.description,
                nameOfPayee: paymentVoucher?.drTo,
                rate: 0,
                amount: Number(stripCommas(paymentVoucher?.amountRequested) || stripCommas(paymentVoucher?.amoun)),
                total: paymentVoucher.total,
                dayOfPayment: paymentVoucher.dayOfPayment,
                userId: paymentVoucher?.userId,
                monthOfPayment: paymentVoucher.monthOfPayment,
                yearOfPayment: paymentVoucher.yearOfPayment,
                amountInWords: paymentVoucher?.amountInWords,
                comment: paymentVoucher?.comment
                // scheduleNO: 0,
                // serialNo: "",
                // economicCodeNo: "",
                // treasuryCashOffice: "B/C",
                // signingOfficer: "",
                // counterSigningOfficer: ""
            }
        )
    }, [])

    const stripCommas = (val) => {
        let num = String(val).replace(/,/g, '')
        return parseFloat(num);
    }

    const handleSigningOfficerId = async (e) => {
        const res = await get(`/staffById/${e.value}`)
        setSchedule({
            ...schedule,
            signingOfficer: res?.data.signature,
        })
    };

    const getApproversMDAs = async () => {
        const res = await get(`/mdas`)
        if (res.status === 'success') {
            // this.setState({
            const roleList = res?.data.map((item) => ({
                name: `${item.name}`,
                value: item.id,
            }));
            setMdaList(roleList);

        };
    };

    

    const handleCounterSigningOfficerId = async (e) => {
        const res = await get(`/staffById/${e.value}`)
        setSchedule({
            ...schedule,
            counterSigningOfficerSignature: res?.data.signature,
            counterSigningOfficer: res?.data.id
        })
    };



    const getCounterSigningOfficer = async (e) => {

        const res = await get(`/ModuleUsers/DFAs/${e}`)
        setCounterSigningOfficers(res?.data)
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'economicCode') {
            setSchedule((prev) => ({ ...prev, [name]: value }));

        }

        else {
            setSchedule((prev) => ({ ...prev, [name]: Number(value) }));

        }
    };

    return (
        <div>
            {/* <div className="w-100 flex space-between flex-v-center">{title}</div> */}
            <div className="flex flex-h-center">
                <div className="col-9 pd-r-0">
                    <div
                        style={{
                            boxShadow: '0px 2px 6px #0000000A'
                            // backgroundColor: 'white'
                        }}
                    >
                        <div>
                            <div className="w-100 m-t-20 p-x-10 p-y-10 bg_green  space-between flex   flex-v-center flex-h-end">
                                <div className="bold-text">SCHEDULE</div>
                            </div>

                            <div
                                style={{
                                    boxShadow: '0px 2px 6px #0000000A',
                                    backgroundColor: 'white',
                                    padding: '20px'
                                }}
                            >
                                <TextInput
                                    className="w-100 m-b-10"
                                    name="scheduleNO"
                                    label="Schedule Number"
                                    type="number"
                                    value={schedule?.scheduleNO}
                                    onChange={handleChange}
                                />

                                {/* <TextInput
                                    className="w-100 m-b-10"
                                    label="PV No"
                                    name="pvNo"
                                    value={getPVs()}
                                    disabled
                                    onChange={handleChange}
                                /> */}

                                <TextInput
                                    className="w-100 m-b-10"
                                    label="Economic Code"
                                    name="economicCode"
                                    value={schedule?.economicCode}
                                    onChange={handleChange}
                                />

                                {/* <TextInput
                                    className="w-100 m-b-10"
                                    label="Amount"
                                    name="amount"
                                    type="number"
                                    value={numberWithCommas(schedule?.amount) }
                                    disabled
                                    onChange={handleChange}

                                /> */}

                                <TextInput
                                    className="w-100 m-b-10"
                                    label="Amount in words"
                                    name="amountInWords"
                                    disabled
                                    value={paymentVoucher?.amountInWords}
                                    onChange={handleChange}

                                />

                                <div className=' flex m-b-10 space-between w-100 form-group '>
                                    <div className='flex-basis-100'>
                                        <SelectApprovers
                                            mdaLabel="Signing Officer's MDA"
                                            employeeLabel="Signing Officer"
                                            paymentVoucher={paymentVoucher}
                                            setPaymentVoucher={setSchedule}
                                            handleApproverId={handleSigningOfficerId}
                                        />
                                    </div>

                                </div>
                                <div className='m-b-10 w-100 form-group '>
                                    <label>Countersigning Officer's MDA</label>
                                    <Select
                                        placeholder="Countersigning Officer's MDA..."
                                        color='rgba(120,120,120,0.8)'
                                        className='m-b-10 w-w00'
                                        onChange={(value) => {
                                            setCounterSigningOfficerName(value);
                                            getCounterSigningOfficer(value?.value);
                                            // setApprovers(value);
                                        }}
                                        value={counterSigningOfficerName}
                                        options={mdaList.map((m) => ({
                                            label: m.name,
                                            value: m.value,
                                        }))}
                                    />

                                </div>

                                <div className='m-b-10 w-100 form-group '>
                                    <label>Countersigning Officer</label>
                                    <Select
                                        placeholder='Select Countersigning Officer'
                                        color='rgba(120,120,120,0.8)'
                                        className='m-b-10 w-w00'
                                        onChange={(value) => {
                                            setScheduleEmployeeName(value);
                                            handleCounterSigningOfficerId(value);
                                            // setApprovers(value);
                                        }}
                                        value={scheduleEmployeeName}
                                        options={counterSigningOfficers?.map((m) => ({
                                            label: m.user,
                                            value: m.userId,
                                        }))}
                                    />

                                </div>


                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default ScheduleForm;