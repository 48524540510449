import { useEffect, useState } from "react";
import { get } from "../../utility/fetch";
import { numberWithCommas } from "../../utility/general";
import Select from "react-select";
import { Pagination } from "antd";

const tableHeaders = [
    'Bank',
    'Account Number',
    'Account Name',
    'Account Type',
    'Balance',
    'Date Modified',
];

const BalanceReport = () => {

    const [filteredRequest, setFilteredRequests] = useState([]);
    const [allHistory, setAllHistory] = useState([])
    const [banks, setBanks] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [bankId, setBankId] = useState(0);
    const [accountId, setAccountId] = useState(0);
    const [dateFrom, setDateFrom] = useState(0);
    const [dateTo, setDateTo] = useState(0);
    const [showTable, setShowTable] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const getBanks = async () => {
        setIsLoading(true)
        let res = await get("/Ledger/Bank")
        if (res?.status === "success") {

            setBanks(res?.data?.map((item) => (
                {
                    label: item?.description,
                    value: item?.id
                }
            )))

            setIsLoading(false)
        }
    }

    const getAccounts = async (id) => {
        setIsLoading(true)
        let res = await get(`/Ledger/Account/Bank/${id}`)
        if (res?.status === "success") {
            setAccounts(res?.data?.map((item) => (
                {
                    label: item?.beneficiary,
                    value: item?.id
                }
            )))
            setIsLoading(false)
        }
    }

    const getHistory = async () => {
        setIsLoading(true)
        let res = await get("/Ledger/History");
        if (res?.status === "success") {
            setFilteredRequests(res?.data);
            setAllHistory(res?.data);
        }
        setIsLoading(false)
    }

    const getHistoryRange = async (from, to) => {
        setIsLoading(true)
        let res = await get(`/Ledger/History/Range?dateFrom=${from}&dateTo=${to}`);
        if (res?.status === "success") {
            setFilteredRequests(res?.data)
        }
        setIsLoading(false)
    }

    const getBankHistory = async (id) => {
        setIsLoading(true)
        let res = await get(`/Ledger/History/Bank/${id}`);
        if(res?.status === "success"){
            setFilteredRequests(res?.data)
        }
        setIsLoading(false)
    }

    const getAccountHistory = async (id) => {
        setIsLoading(true)
        let res = await get(`/Ledger/History/Account/${id}`);
        if(res?.code === 1){
            setFilteredRequests(res?.data)
        }
        else{
            setFilteredRequests([])
        }
        setIsLoading(false)
    }

    const handleAllHistory = () => {
        setFilteredRequests(allHistory)
        setShowTable(!showTable)
    }


    useEffect(() => {
        getBanks();
        getHistory();
    }, [])


    return (
        <div>
            <div className="flex w-100 space-between">
                <div className="w-48"><Select options={banks} onChange={(value) => { setBankId(value?.value); getAccounts(value?.value) }} /> </div>
                <div className="w-48"><button onClick={() => getBankHistory(bankId)} className="btn">View Bank Balance history</button></div>
            </div>
            <div className="flex w-100 m-t-20 space-between">
                <div className="w-48"><Select options={accounts} onChange={(value) => { setAccountId(value?.value) }} /> </div>
                <div className="w-48"><button onClick={() => getAccountHistory(accountId)} className="btn">View Account history</button></div>
            </div>
            <div className="flex w-100 m-t-20 space-between">
                <div className="w-48"><input className="w-48 p-10" type="date" onChange={(e) => { setDateFrom(e.target?.value) }} /> <input className="w-48 p-10" type="date" onChange={(e) => { setDateTo(e.target.value) }} /></div>
                <div className="w-48"><button onClick={() => getHistoryRange(dateFrom, dateTo)} className="btn">View Account history</button></div>
            </div>
            <button className="btn w-100 m-t-20" onClick={handleAllHistory}>Show All</button>
            <div className="overflow-table m-t-20">
                
                {showTable && <table className="table-view">
                    <thead className="">
                        <tr className="tr-bordered">
                            {tableHeaders.map((th, index) => (
                                <th key={index} className="th th-bordered">
                                    <p>{th}</p>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="table-body">
                        {filteredRequest?.map((item) => (
                            <tr role="presentation" key={item?.id}>
                                <td className="td p-r td-bordered">
                                    {item?.bank}
                                </td>
                                <td className="td p-r td-bordered">
                                    {item?.accountNo}
                                </td>
                                <td className="td p-r td-bordered">
                                    {item?.beneficiary}
                                </td>
                                <td className="td p-r td-bordered">
                                    {item?.accountType}
                                </td>
                                <td className="td p-r td-bordered">
                                    {`₦${numberWithCommas(Number(parseFloat(item?.balance)).toFixed(2))}`}
                                </td>
                                <td className="td p-r td-bordered">
                                    {new Date(item?.dateUpdated).toUTCString()}
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>}
            </div>
           {showTable && <div style={{ marginTop: '20px' }}>
                <Pagination
                    showSizeChanger
                    // pageSize={pageSize}
                    pagination={true}
                    // onShowSizeChange={paginationChangeHandler}
                    // current={pageNumber}
                    // total={paginationLength}
                    // showTotal={true}
                    // onChange={paginationChangeHandler}
                    // pageSizeOptions={pageSizeOptions}
                    responsive
                />
            </div>}

           
        </div>
    )
}

export default BalanceReport;