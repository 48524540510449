import * as React from 'react';
import moment from 'moment';
import Accordion from '../layouts/Accordion';

// icons
import { BsCaretDownFill } from 'react-icons/bs';
import { BsFillCaretUpFill } from 'react-icons/bs';
import CommentModal from '../layouts/CommentModal';
import Successmodal from '../layouts/SuccessModal';
import ErrorModal from '../layouts/ErrorModal';
import { PDFExport } from '@progress/kendo-react-pdf';
import Cookies from 'js-cookie';
// Api
import { post, patch, get } from '../../utility/fetch';
import EdsgLogo from '../../assets/images/edsg-logo-250.png';
// Image
import festac from '../../assets/images/Festac-logo.jpg';
import { formatFileUrl, formatReviewStatus } from '../../utility/general';
import CompletionFormModal from './CompletionFormModal';
import PictureModal from './PictureModal';

const borderBottom = {
  borderBottom: '1px solid rgba(108,108,108,.4)'
};

const tableHeaders = [
  'Category',
  'Item',
  'Currency',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec'
];

const data = [
  {
    category: 'Inventories',
    item: 'Exam/Termly and Terminal Examinations',
    currency: 'Naira-NGN',
    jan: 30,
    feb: 30,
    mar: 63,
    apr: 30,
    may: 17,
    jun: 30,
    jul: 29,
    aug: 25,
    sept: 31,
    oct: 76,
    nov: 42,
    dec: 62
  },
  {
    category: 'Inventories',
    item: 'Exam/Termly and Terminal Examinations',
    currency: 'Naira-NGN',
    jan: 30,
    feb: 30,
    mar: 63,
    apr: 30,
    may: 17,
    jun: 30,
    jul: 29,
    aug: 25,
    sept: 31,
    oct: 76,
    nov: 42,
    dec: 62
  }
];

const total = [
  {
    total: 'Total',
    jan: 30,
    feb: 30,
    mar: 63,
    apr: 30,
    may: 17,
    jun: 30,
    jul: 29,
    aug: 25,
    sept: 31,
    oct: 76,
    nov: 42,
    dec: 62
  }
];

const BudgetOverviewDetails = (props) => {
  const pdfExportComponent = React.useRef(null);
  const [requestState, setRequestState] = React.useState({
    comment: '',
    learningRequestId: '',
    approvalStatus: 1
  });
  const [showCommentModal, setShowCommentModal] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showCompletionModal, setShowCompletionModal] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [successMsg, setSuccessMsg] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState('');
  const [btnText, setBtnText] = React.useState('');
  const [reviewDetails, setReviewDetails] = React.useState({});
  const [showPictureModal, setShowPictureModal] = React.useState(false);

  const openPicture = () => {
    setShowPictureModal(true);
  };

  const closePictureModal = () => {
    setShowPictureModal(false);
  };

  const closeModal = () => {
    setShowErrorModal(false);
    setShowSuccess(false);
    setShowCommentModal(false);
  };

  const getDifferenceInDays = (date1, date2) => {
    const diffInMs = moment(date2).diff(moment(date1), 'days');
    // const diffInMs = Math.abs(date2 - date1);
    return diffInMs + 1;
  };

  React.useEffect(() => {
    const jvhb = props;
    // debugger;
  }, []);

  return (
    <div className="" style={{ transition: 'all 0.6s ease-in' }}>
      <PDFExport
        paperSize="A2"
        margin="2mm"
        fileName={props.cardDetails.title}
        ref={pdfExportComponent}
      >
        <div
          className="flex-between flex-align w-100 m-b-10"
          style={{
            boxShadow: '0px 2px 6px #0000000A'
            // backgroundColor: 'white'
          }}
        >
          <table className="table-view">
            <thead className="">
              <tr className="tr-bordered">
                <th className="th th-bordered">
                  <p>Category</p>
                </th>
                <th className="th th-bordered">
                  <p>Item</p>
                </th>
                <th className="th th-bordered">
                  <p>Currency</p>
                </th>
                <th className="th th-bordered">
                  <p>Budget Type</p>
                </th>
                <th className="th th-bordered">
                  <p>Total</p>
                </th>
                <th className="th th-bordered">
                  <p>Grand Total</p>
                </th>
              </tr>
            </thead>
            <tbody className="table-body">
              {props.cardDetails?.map((item) => (
                <tr role="presentation">
                  <td className="td p-r td-bordered">{item.category}</td>
                  <td className="td p-r td-bordered">{item.item}</td>
                  <td className="td p-r td-bordered">{item.currency}</td>
                  <td className="td p-r td-bordered">{item.budgetType}</td>
                  <td className="td p-r td-bordered">{item.total}</td>
                  <td className="td p-r td-bordered">{item.grandTotal}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </PDFExport>
      {/* {props.cardDetails.status === 1 ||
        (props.cardDetails.status === 7 && ( */}

      {/* ))} */}
    </div>
  );
};
export default BudgetOverviewDetails;
