
import parser from 'html-react-parser';

const VoucherPreview = ({
    paymentVoucher,
    pay
}) => {

    const numberWithCommas = (x) => {
        if (x === '') {
            return
        }
        if (String(x).includes(',')) {
            return String(x)?.replace(/,/g, '')?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        else {
            return x?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        }
    }
    return (
        <div className="page bg-white m-b-50">
            <div className="flex space-between">
                <h2>EDO STATE GOVERNMENT OF NIGERIA</h2>
                <p>TREASURY ONE</p>
            </div>

            <h4 className='right-text'>DEPARTMENT NO: {paymentVoucher?.departmentNo}</h4>
            <p>Checked and passed for payment at B/C only.</p>
            <div>
                <h2>PAYMENT VOUCHER</h2>
                <p>(Other Charges)</p>
            </div>
            <table className='float-right '>
                <tr>
                    <th>Station</th>
                    <th>Month</th>
                    <th>P.V.NO.</th>
                </tr>
                <tr>
                    <td>B/C</td>
                    <td>{`${paymentVoucher?.monthOfPayment} ${paymentVoucher?.yearOfPayment}`}</td>
                    <td>{paymentVoucher?.pvNo}</td>
                </tr>
            </table>
            <p className='m-t-40'>Administrative Code:{` ${paymentVoucher?.administrativeCode || paymentVoucher?.head || "-"}`}</p>
            {(paymentVoucher?.economicCode || paymentVoucher?.subhead) && <p>Economic Code: {` ${paymentVoucher?.economicCode || paymentVoucher?.subhead || "-"}`}</p>}
            <p>Dr to: {paymentVoucher.drTo }</p>
            {/* {paymentVoucher.poNo && <p>PO Number: {paymentVoucher.poNo || "-"}</p>} */}
            {paymentVoucher.programCode && <p>Program Code: {paymentVoucher.programCode || "-"}</p>}

            <table className='m-t-20 w-100'>
                <tr>
                    <th rowSpan="2">Date</th>
                    <th rowSpan="2">Detailed Description Of Service or ArticleSe</th>
                    <th rowSpan="2">Rate</th>
                    <th colSpan={2}>Amount</th>
                </tr>
                <tr>
                    <th>₦</th>
                    <th>K</th>
                </tr>
                <tr>
                    <td>{new Date(paymentVoucher?.date).toLocaleDateString()}</td>
                    <td width="50%">{parser(paymentVoucher?.description?.toUpperCase()?.replaceAll("<td>", "<td className='border-table'>")?.replace(/\s*&nbsp;\s*/ig, " ")) }
                    </td>
                    <td></td>
                    <td className='center-text'>{(paymentVoucher?.amountRequested === 0 || !paymentVoucher?.amountRequested) ? numberWithCommas(String(paymentVoucher?.amount)?.split(".")[0]) : numberWithCommas(String(paymentVoucher?.amountRequested)?.split(".")[0])}</td>
                    <td className='center-text'>{String(paymentVoucher?.amount)?.split(".")[1]  || String(paymentVoucher?.amountRequested)?.split(".")[1] || "00"}  </td>
                </tr>
                <tr>
                    <td colSpan="3">
                        <h3>Financial Authority: GEN.WARRANT {new Date()?.getFullYear()}.... TOTAL ₦</h3>
                    </td>
                    <td className='center-text'>{(paymentVoucher?.amountRequested === 0 || !paymentVoucher?.amountRequested) ? numberWithCommas(String(paymentVoucher?.amount)?.split(".")[0]) : numberWithCommas(String(paymentVoucher?.amountRequested)?.split(".")[0])}</td>
                    <td className='center-text'>{String(paymentVoucher?.amount)?.split(".")[1] || String(paymentVoucher?.amountRequested)?.split(".")[1] || "00"}</td>
                </tr>
                <tr>
                    <td height="24px" colSpan="3"></td>
                    <td height="24px"></td>
                    <td height="24px"></td>
                </tr>
            </table>

            <p className='m-t-40'>
                *I CERTIFY THAT the above account is correct, and was incurred
                under the authority quoted, and that the service have been duly performed,
                and that the rate/price charge is/are according to regulation/contract or fair
                and reasonable and that the amount of  {numberWithCommas(paymentVoucher?.amount)}( {paymentVoucher?.amountInWords} ) Only can be paid under the Sub-head quoted.
            </p>
            <div className='flex space-between'>
                <p className='m-t-40'>
                    place: B/C
                </p>
                <div className='m-t-40'>
                    <img className="signature_img" src={paymentVoucher?.expenditureSignature} alt="expenditure officer's signature" />
                    <hr />
                    <p>Signature of Officer controlling Expenditure</p>
                </div>
            </div>

            <div className=' m-t-40 flex space-between'>
                <p >
                    Date:{new Date(paymentVoucher.date).toLocaleDateString()}
                </p>
                <p>Rank: <span className='w-25 filter_box_border_b center-text p-r-5'>{paymentVoucher?.rankOfSignatory || pay?.rankOfSignatory || ""}</span></p>
            </div>

            <p className='m-t-40'>
                RECEIVED this <span className='w-25 filter_box_border_b '></span> day of <span className='w-25 filter_box_border_b '></span>, <span className='w-25 filter_box_border_b '></span>, in payment of the above
                Acount the sum of <span className='w-50 filter_box_border_b '></span>
            </p>
            <p className='m-t-40'>
                Witness to mark
                <span className='w-50 filter_box_border_b'></span>
            </p>

            <div className="flex space-between m-t-40">
                <p>The certificate must be made to apply to the circumstances of the payment</p>
                <div>
                    <hr />
                    <p className="float-right">Signature of Receiver</p>
                    <p>GPBM 206</p>
                </div>
            </div>

        </div>
    )
}

export default VoucherPreview;