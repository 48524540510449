import Cookies from "js-cookie";
import { logout } from "./auth";

const paths = {
  login: 'login-path'
};

async function fetchBackend(endpoint, method, auth, body, params) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  const fetchObject = { method, headers };
  const path = paths[endpoint] || endpoint;
  let url = `${process.env.REACT_APP_BACKEND_URL_BUDGET}${path}`;

  if (body) {
    fetchObject.body = JSON.stringify(body);
  }

  if (params) {
    const paramsArray = Object.keys(params).map(
      (key) => {
        let val = encodeURIComponent(params[key])
        return `${encodeURIComponent(key)}=${val}`}
    );

    url += `?${paramsArray.join('&')}`;
  }

  if (auth) {
    const token = sessionStorage.getItem("budgetToken");
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
  }

  const response = await fetch(url, fetchObject);
  // if(response.status === 401){
  //   logout()
  // }
  const response_1 = await checkHttpStatus(response);
  
  return parseJSON(response_1);
}

export const get = (endpoint, params, auth = true) =>
  fetchBackend(endpoint, 'GET', auth, null, params);

export const post = (endpoint, body, auth = true) =>
  fetchBackend(endpoint, 'POST', auth, body);

export const put = (endpoint, body, auth = true) =>
  fetchBackend(endpoint, 'PUT', auth, body);

export const patch = (endpoint, body, auth = true) =>
  fetchBackend(endpoint, 'PATCH', auth, body);

export const del = (endpoint, body, auth = true) =>
  fetchBackend(endpoint, 'DELETE', auth, body);

function checkHttpStatus(response) {
  if (response && response.ok) {
    return response;
  }
console.log(error)
  const errorText =
    response && response.statusText ? response.statusText : 'Unknown Error';
  const error = new Error(errorText);
  error.response = response;
  throw error;
}

function parseJSON(response) {
  return response.json();
}
