import React from 'react';
import { Switch, Route } from 'react-router';
import { pure } from 'recompose';

import ProtectedRoute from './ProtectedRoute';
import {
  ManageRequest,
  PaymentPool,
  PaymentAdvice
} from '../components/pages/AccountantGeneral';
import {
  ManageRequest as ManageCommissionerRequest,
  PaymentPool as CommissionerPaymentPool
} from '../components/pages/Commissioner';
import Dashboard from '../components/pages/Dashboard';
import SessionHoc from '../components/HOC/SessionHOC'
import ExpenseRegister from '../components/pages/ExpenseRegister';
import PaymentRequest from '../components/pages/PaymentRequest';
import Requests from '../components/pages/Requests';
import RequestDetails from '../components/pages/RequestDetails';
import ManageRequests from '../components/pages/ManageRequest';
// import ManageAuditRequest from '../components/pages/ManageAuditRequest';
// import ManageExpeditionRequest from '../components/pages/ManageExpeditionRequest';
import ManageAGRequest from '../components/pages/ManageAGRequest';
// import ManageFinalRequest from '../components/pages/ManageFinalRequest';
import ReportSummary from '../components/pages/ReportSummary';
import TreasuryRequest from '../components/pages/TreasuryRequest';
import Settings from '../components/pages/Settings';
import TreasuryRequestDetails from '../components/pages/TreasuryRequestDetails';

// import Approvals from '../components/pages/Approvals';
import ManagementAccount from '../components/pages/ManagementAccount';
import InitiateCapitalVouchers from '../components/pages/InitiateCapitalVouchers';
import InitiateOverheadVouchers from '../components/pages/InitiateOverheadVouchers';
import InitiateSalaryVouchers from '../components/pages/InitiateSalaryVouchers';
import CashBudget from '../components/pages/CashBudget';
import BalanceSettings from '../components/pages/BalanceSettings';
import CashBudgetReview from '../components/pages/CashBudgetReview';
import BudgetOverview from '../components/pages/BudgetOverview';
import ManageManagementRequest from '../components/pages/ManageManagementRequest';
import ManageTCARequest from '../components/pages/ManageTCARequest';
import ManageDFARequest from '../components/pages/ManageDFARequest';
import ArchiveReport from '../components/pages/ArchiveReport';
import BalanceReport from '../components/pages/BalanceReport';
import Finance from '../components/pages/Finance';
// import ManagePSRequest from '../components/pages/ManagePSRequest';
// import ManageInspectorateRequest from '../components/pages/ManageInspectorateRequest';

export default pure(() => (
  <Switch>
    <Route path="/treasuryops/" exact component={Dashboard} />
    {/* <ProtectedRoute path="/treasuryops/dashboard" exact component={Dashboard} /> */}
    <Route
      path="/treasuryops/expense_control/payment_request"
      exact
      component={SessionHoc(PaymentRequest)}
    />
    <Route
      path="/treasuryops/expense_control/expense_register"
      exact
      component={SessionHoc(ExpenseRegister)}
    />

    <Route
      path="/treasuryops/accountant_general/payment_advice"
      exact
      component={SessionHoc(PaymentAdvice)}
    />
    <Route
      path="/treasuryops/accountant_general/manage_request"
      exact
      component={SessionHoc(ManageRequests)}
    />
    <Route
      path="/treasuryops/manage_audit_request"
      exact
      component={SessionHoc(ManageDFARequest)}
    />
    <Route
      path="/treasuryops/manage_final_request"
      exact
      component={SessionHoc(ManageDFARequest)}
    />
    <Route
      path="/treasuryops/cash_budget"
      exact
      component={SessionHoc(CashBudget)}
    />
    <Route
      path="/treasuryops/cash_budget_review"
      exact
      component={SessionHoc(CashBudgetReview)}
    />
    <Route
      path="/treasuryops/manage_ps_request"
      exact
      component={SessionHoc(ManageDFARequest)}
    />
    <Route
      path="/treasuryops/manage_inspectorate_request"
      exact
      component={SessionHoc(ManageDFARequest)}
    />
    <Route
      path="/treasuryops/archived"
      exact
      component={SessionHoc(ArchiveReport)}
    />
    <Route
      path="/treasuryops/manage_expedition_request"
      exact
      component={SessionHoc(ManageDFARequest)}
    />
    <Route
      path="/treasuryops/initiate_capital"
      exact
      component={SessionHoc(InitiateCapitalVouchers)}
    />
    <Route
      path="/treasuryops/initiate_salary"
      exact
      component={SessionHoc(InitiateSalaryVouchers)}
    />
    <Route
      path="/treasuryops/initiate_overhead"
      exact
      component={SessionHoc(InitiateOverheadVouchers)}
    />
    <Route
      path="/treasuryops/manage_AG_request"
      exact
      component={SessionHoc(ManageAGRequest)}
    />
    <Route
      path="/treasuryops/balance"
      exact
      component={SessionHoc(BalanceReport)}
    />
    <Route
      path="/treasuryops/manage_Mgt_account_request"
      exact
      component={SessionHoc(ManageManagementRequest)}
    />
    <Route
      path="/treasuryops/manage_DFA_request"
      exact
      component={SessionHoc(ManageDFARequest)}
    />
    <Route
      path="/treasuryops/treasury_cash_account_request"
      exact
      component={SessionHoc(ManageTCARequest)}
    />

    <Route
      path="/treasuryops/accountant_general/payment_pool"
      exact
      component={PaymentPool}
    />

    <Route
      path="/treasuryops/commissioner/manage_request"
      exact
      component={ManageCommissionerRequest}
    />
    <Route
      path="/treasuryops/commissioner/payment_pool"
      exact
      component={CommissionerPaymentPool}
    />
    <Route
      path="/treasuryops/management_account"
      exact
      component={SessionHoc(ManagementAccount)}
    />
    <Route
      path="/treasuryops/treasury_request"
      exact
      component={SessionHoc(TreasuryRequest)}
    />
     <Route
      path="/treasuryops/balance-settings"
      exact
      component={SessionHoc(BalanceSettings)}
    />

<Route
      path="/treasuryops/finance-dashboard"
      exact
      component={SessionHoc(Finance)}
    />

    <Route
      path="/treasuryops/budget_overview"
      exact
      component={BudgetOverview}
    />
    <Route
      path="/treasuryops/manage_request"
      exact
      component={ManageRequests}
    />
    <Route path="/treasuryops/requests" exact component={Requests} />
    <Route
      path="/treasuryops/treasury_request/:id"
      exact
      component={TreasuryRequestDetails}
    />
    <Route
      path="/treasuryops/report_details"
      exact
      component={RequestDetails}
    />
    <ProtectedRoute
      path="/treasuryops/report"
      exact
      component={ReportSummary}
    />
    <ProtectedRoute
      path="/treasuryops/settings"
      exact 
      component={SessionHoc(Settings)}
    />
    <Route path="/treasuryops/report" exact component={ReportSummary} />
    {/* <Route path="/treasuryops/approvals" exact component={Approvals} /> */}

    <Route render={() => <h1>Error 404. Page not found.</h1>} />
  </Switch>
));
