import { Checkbox, Comment } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlinePaperClip } from "react-icons/ai";
import { Radio } from 'antd';
import { IoMdClose } from "react-icons/io";
import { MdDelete } from "react-icons/md";

import UploadButton from "../layouts/UploadButton";
import Spinner from "./spinner/spinner";
import TextareaInput from "./TextareaInput";
import CashBook from "../layouts/CashBook";
import Select from "react-select";
import { get } from "../../utility/fetch";
import TextInput from "./TextInput";
import { toast } from "react-toastify";


const ReviewForm = ({ approve, ag, decline, cashData, details, review, view, type, isApproved, closeModal, loading }) => {
  const [voucherComment, setVoucherComment] = useState("")
  const [scheduleComment, setScheduleComment] = useState("")
  const [mFilesArray, setMFilesArray] = useState([])
  const [value, setValue] = useState(0);
  const [bank, setBank] = useState({});
  const [bankList, setBankList] = useState([]);
  const [bankAccount, setBankAccount] = useState({});
  const [accountList, setAccountList] = useState([]);
  const [isIspo, setIsIspo] = useState(false);
  const [noOfSplit, setNoOfSplit] = useState(0);



  const getBank = async () => {
    const response = await get("/Ledger/Bank");
    if (response.status === "success") {

      setBankList(response?.data);
    }
    else {
      console.log(response)
    }

  }

  const getBankAccount = async (id) => {
    const response = await get(`/Ledger/Account/Bank/${id}`);
    if (response.status === "success") {

      setAccountList(response?.data)
    }
    else {
      console.log(response)
    }

  }

  useEffect(() => {
    getBank();
  }, [])


  const onISPOChange = (e) => {
    setIsIspo(!isIspo);
  };

  const handleSplitChange = (e) => {
    setNoOfSplit(e.target.value);
  }



  const onChange = (e) => {
    setValue(e.target.value);
  };

  const submit = () => {

    if(view === "cash"){
      if (!voucherComment) {
        toast.error("Please enter comments")
        return;
      }
    }
    else if(!voucherComment || !scheduleComment) {
      toast.error("Please fill in the voucher and schedule comments")
      return;
    }

    let comment;

    // if (isApproved === false) {   // If the governor has not appproved, hardcoded values to bypass validation    /// Pre-Update
    //   comment = {
    //     voucher: {
    //       comment: voucherComment,
    //       chequeUrl: [],
    //       approverRoleId: 12,
    //       accountId: bankAccount?.value || 0
    //     },
    //     schedule: {
    //       comment: scheduleComment,
    //       approverRoleId: 12
    //     }
    //   }
    // }
    // else {
    //   if (mFilesArray.length > 0) {
    //     comment = {
    //       voucher: {
    //         id: details?.voucher?.id,
    //         comment: voucherComment,
    //         chequeUrl: mFilesArray,
    //         approverRoleId: value,
    //         accountId: bankAccount?.value || 0
    //       },
    //       schedule: {
    //         id: details?.schedule?.id,
    //         comment: scheduleComment,
    //         approverRoleId: value
    //       }
    //     }
    //   }
    //   else {
    //     comment = {
    //       voucher: {
    //         comment: voucherComment,
    //         chequeUrl: [],
    //         approverRoleId: value,
    //         accountId: bankAccount?.value || 0
    //       },
    //       schedule: {
    //         comment: scheduleComment,
    //         approverRoleId: value
    //       }
    //     }
    //   }
    // }




    ///////// UPDATE //////////////

    if (mFilesArray.length > 0) {
      comment = {
        voucher: {
          id: details?.voucher?.id,
          comment: voucherComment,
          chequeUrl: mFilesArray,
          approverRoleId: value,
          accountId: bankAccount?.value || 0,
        },
        schedule: {
          id: details?.schedule?.id,
          comment: scheduleComment,
          approverRoleId: value
        }
      }
    }
    else {
      comment = {
        voucher: {
          comment: voucherComment,
          chequeUrl: [],
          approverRoleId: value,
          accountId: bankAccount?.value || 0,
          isISPO: isIspo,
          noOfSplit: Number(noOfSplit)

        },
        schedule: {
          comment: scheduleComment,
          approverRoleId: value
        }
      }
    }



    if (type === "approve" || type === "MGT") {
      approve(details, comment);
      closeModal();

    }
    else if (type === "decline") {
      decline(details, comment);
      closeModal();

    }
    else if (type === "review") {
      review(details, comment);
      closeModal();

    }

  }

  const handleVChange = (e) => {
    if (view === "cash") {
      setVoucherComment({ comment: e.target.value, cashData });
    }
    else {
      setVoucherComment(e.target.value);
    }
  }

  const handleSChange = (e) => {
    setScheduleComment(e.target.value);
  }

  const recImagg = (value) => {
    setMFilesArray([...mFilesArray, value.docPath]);

  };

  const del = (id) => {
    let pp = mFilesArray.filter((x) => x.key !== id);
    setMFilesArray(pp);
  };

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box w-80">
        <div

        >
          <div className="content">

            {
              type === 'MGT' &&
              <div>
                <h2 className="m-b-20 bold-text"> BANK: {details?.voucher?.account?.bank}</h2>
                <h2 className="m-b-20 bold-text"> ACCOUNT NAME: {details?.voucher?.account?.beneficiary?.toUpperCase()}</h2>
              </div>
            }

            {
              view === "cash" ?
                <TextareaInput
                  className="w-100 m-b-10"
                  name="comment"
                  label="Comments on Cash Budget:"
                  type="text"
                  rows="5"
                  onChange={handleVChange}
                />
                :
                <div className="w-100">
                  <TextareaInput
                    className="w-100 m-b-10"
                    name="comment"
                    label="Comments on Voucher:"
                    type="text"
                    rows="5"
                    onChange={handleVChange}
                  />

                  <TextareaInput
                    className="w-100 m-b-10"
                    name="comment"
                    label="Comments on Schedule:"
                    type="text"
                    rows="5"
                    onChange={handleSChange}
                  />
                </div>

            }

            {
              ((ag === "ag") || (ag === "ag" && details?.voucher?.voucherType === "Salary Voucher")) &&
              <div className="m-b-10 m-t-10  w-100">

                <p>Select Payment Bank:</p>
                <Select
                  label="Select Payment Bank"
                  color='rgba(120,120,120,0.8)'
                  className='m-b-10 w-100'
                  onChange={(value) => {
                    setBank(value);
                    getBankAccount(value?.value);
                  }}
                  value={bank}
                  options={bankList.map((m) => ({
                    label: m.description,
                    value: m.id,
                  }))}
                />

                <p>Select Bank Account:</p>
                <Select
                  label="Select Bank Account"
                  color='rgba(120,120,120,0.8)'
                  className='m-b-10 w-100'
                  onChange={(value) => {
                    setBankAccount(value);
                  }}
                  value={bankAccount}
                  options={accountList.map((m) => ({
                    label: m.beneficiary,
                    value: m.id,
                  }))}
                />

                <div className=" m-t-20 m-b-10 bold">Select Payment Office:</div>
                <Radio.Group onChange={onChange} className="m-b-20" value={value}>
                  <Radio value={6}>Management Account</Radio>
                  <Radio value={7}>Treasury Cash Account</Radio>
                  <Radio value={12}>Governor</Radio>
                </Radio.Group>


                <div>
                  {(ag === "ag" && isApproved) && <p className=" m-t-20 m-b-10 bold">Check Payment ISPO:</p>}
                  {(ag === "ag" && isApproved) && <Checkbox className="m-b-20" onChange={onISPOChange} value={isIspo} >ISPO</Checkbox>}
                </div>


                {
                  isIspo &&
                  <TextInput
                    className="w-100 m-b-10"
                    name="comment"
                    label="Number of Partitions:"
                    type="number"
                    onChange={handleSplitChange}
                  />
                }


              </div>

            }

            {
              type === "MGT" &&
              <div style={{ margin: '10px', minWidth: '150px', width: "100%" }} >
                <p>Select Payment Bank:</p>
                <Select
                  label="Select Payment Bank"
                  color='rgba(120,120,120,0.8)'
                  className='m-b-10 w-100'
                  onChange={(value) => {
                    setBank(value);
                    getBankAccount(value?.value);
                  }}
                  value={bank}
                  options={bankList.map((m) => ({
                    label: m.description,
                    value: m.id,
                  }))}
                />

                <p>Select Bank Account:</p>
                <Select
                  label="Select Bank Account"
                  color='rgba(120,120,120,0.8)'
                  className='m-b-10 w-100'
                  onChange={(value) => {
                    setBankAccount(value);
                  }}
                  value={bankAccount}
                  options={accountList.map((m) => ({
                    label: m.beneficiary,
                    value: m.id,
                  }))}
                />
                <UploadButton width='50%' sendImagg={recImagg} />
                <i> Supported Formats: jpeg, png, gif, pdf, ppt, pptx, vnd.ms-excel, xls, xlsx, vnd.openxmlformats-officedocument.wordprocessingml.document, doc, vnd.openxmlformats-officedocument.spreadsheetml.sheet</i>
              </div>
            }

            {Array.isArray(mFilesArray) && mFilesArray?.map((x) => (
              <div
                style={{
                  padding: '2px',
                  marginBottom: '1px',
                  marginTop: '2px',
                  width: '80%',
                }}
                // style={{display:(mFilesVal.name=="" ||mFilesVal.name==null||mFilesVal.name==undefined ? "none":"flex"), marginTop:"5px", border:"2px solid red"}}
                className='flex-between mFiles-hover flex-align'>
                <div className='flex-align'>
                  <AiOutlinePaperClip />
                  <a href={x} style={{ marginLeft: 'px', marginBottom: '0px' }}>
                    View Document
                  </a>
                  <div
                    className='pointer'
                    style={{ display: 'inline', marginLeft: '6px', marginBottom: '0px' }}
                    onClick={() => {
                      del(x.key);
                    }}>
                    <MdDelete style={{ fontSize: '16px' }} />
                  </div>
                </div>
              </div>
            ))}


            {<div className="flex space-between w-100">
              <button
                onClick={submit}
                disabled={loading}
                className="btn btn-large w-100"
              >
                {loading && <Spinner />} {(type === "approve" || type === "MGT") ? "Approve" : (type === "review") ? "Review" : "Decline"}
              </button>
            </div>}

          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewForm;
