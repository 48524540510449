import { IoMdClose } from "react-icons/io";

const CashBudgetEditor = ({ index, data, setPayload, closeModal }) => {


    const updateObjectAtIndex = (index, newData) => {
        if (index >= 0 && index < data.length) {
            // Create a copy of the array
            const newArray = [...data];
            // Update the object at the specified index
            newArray[index] = { ...newArray[index], ...newData };
            // Update the state with the modified array
            setPayload(newArray);
        } else {
            console.error("Index out of range");
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        updateObjectAtIndex(index, {[name]:value })
    }

    return (
        <div className="overlay m-t-20 ">
            <IoMdClose className="close-btn pointer" onClick={() => closeModal()} />
            <div style={{ width: '80%', margin: '0 auto' }} >
                <div className=" no-padding">
                    <div
                        className="m-t-10 p-t-10 flex-h-center modal-content-large"
                        style={{
                            boxShadow: '0px 2px 6px #0000000A',
                            // backgroundColor: 'white'
                        }}
                    >
                        <div className="flex w-100 m-b-20">
                            <div className=" w-30 m-r-20">
                                <label htmlFor="economicCode">Economic Code:</label>
                                <input onChange={handleChange} name="economicCode" defaultValue={data[index]?.economicCode} type="number" className="m-r-10  cashInput block" />
                            </div>

                            <div className=" w-30 m-r-20">
                                <label htmlFor="economicCodeDescription"> Economic Code Description:</label>
                                <input onChange={handleChange} name="economicCodeDescription" defaultValue={data[index]?.economicCodeDescription} type="text" className="m-r-10 block cashInput" />
                            </div>
                            <div className=" w-30">
                                <label htmlFor="amount">Amount:</label>
                                <input onChange={handleChange} name="amount" type="number" defaultValue={data[index]?.amount} className="m-r-10 block cashInput" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}
export default CashBudgetEditor;